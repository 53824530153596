import React from "react";
import { people } from "@utils/variables";
import tw from "twin.macro";

import PersonImageExtra from "@components/person/image-extra";
import PersonInfo from "@components/person/info";

const WrapperPersonInfo = tw.div`md:w-1/2 md:mt-60 lg:mt-0`;

const MoreBeata = ({ width, scroll }: { width: number, scroll: any }) => (
  <>
    <PersonImageExtra width={ width } scroll={ scroll } />
    <WrapperPersonInfo>
      <PersonInfo
        width={ width }
        scroll={ scroll }
        headings={'Education'}
        person={people[0]}
      />
      <PersonInfo
        width={ width }
        scroll={ scroll }
        headings={'Customers'}
        person={people[0]}
      />
    </WrapperPersonInfo>
  </>
);

export default MoreBeata;
