import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import { PeopleTypes } from "@utils/types";
import styled from "styled-components";
import tw from "twin.macro";

type PersonInfoProps = {
  headings: string,
  person: PeopleTypes,
  flexStart?: boolean,
  xlWider?: boolean,
  width: number,
  scroll: any
};

const Wrapper = styled.div<{ flexStart: boolean }>`
  ${tw`
    flex mb-15 text-sm
    md:ml-auto md:mb-40
    lg:mb-25 lg:ml-0 lg:text-md lg:overflow-hidden
  `}
  ${({ flexStart }) => flexStart ? tw`lg:justify-start` : tw`lg:justify-end`}
`;

const Headings = tw.div`
  font-pangramBold min-w-100 mr-20 text-portfolioText
  md:max-w-100
  lg:min-w-140 lg:max-w-140 lg:mr-35 lg:text-right lg:-tracking-013
`;

const Content = styled(motion.div)<{ wider: boolean }>`
  ${tw`
    text-second leading-20 -tracking-016
    md:max-w-220
    lg:relative lg:max-w-270 lg:leading-30 lg:-tracking-027
  `}

  ${({ wider }) => wider ? tw`xl:max-w-400` : tw`xl:max-w-360 xl:mr-30`}
  
  b {
    ${tw`font-pangramBold`}
  }
`;

const PersonInfo = ({ headings, person, flexStart, xlWider, width, scroll }: PersonInfoProps) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 120, sectionStart + 350], [400, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref} flexStart={ flexStart }>
      <Headings>
        {t(`About:${headings}`)}
      </Headings>
      <Content style={ width >= 1024 && { left } } wider={ xlWider }>
        {t(`About:Persons:${person}:${headings}1`)}
        <b>{t(`About:Persons:${person}:${headings}2`)}</b>
      </Content>
    </Wrapper>
  );
};

export default PersonInfo;
