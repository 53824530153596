import React from "react";
import { down } from "styled-breakpoints";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

const FigureBlue = styled(motion.svg)`
  ${tw`lg:relative lg:-top-220 lg:left-285`}

  ${down('md')} {
    transform: none !important;
  }  
`;

const FigureRed = styled(motion.svg)`
  ${tw`absolute top-0 w-full lg:top-350 lg:left-150`}

  ${down('md')} {
    transform: none !important;
  }  
`;

const About = ({ width }: { width: number }) => (
  <>
    <FigureBlue
      viewBox="0 0 700 741"
      animate={ width >= 1024 && { x: -285, y: 220 }}
      transition={{ duration: .8, delay: .6 }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(350.000000, 370.500000) rotate(-16.000000) translate(-350.000000, -370.500000) translate(74.000000, 65.000000)">
        <g transform="translate(107.292631, 0.841170)">
          <path d="M84.1695769,128.096604 C79.8551944,128.268666 78.5846597,128.481214 78.9685378,132.867119 C79.1689698,135.151163 82.4947812,138.106588 79.9673004,140.019517 C76.4648371,142.674676 76.4954115,147.991742 79.0670552,148.963389 C85.3212113,151.318282 87.3696937,150.002511 85.470686,154.030795 C83.432395,158.349224 84.753887,161.108971 95.6077863,158.767572 C106.461686,156.4228 140.436599,160.562419 140.436599,160.562419 L147.07803,125.724842 C147.07803,125.724842 122.034229,123.609486 108.350502,125.724842 C94.6633782,127.836824 86.996007,127.98527 84.1695769,128.096604" id="Fill-200" fill="#F496A4" />
          <path d="M189.720096,237.589363 C189.720096,237.589363 185.072793,246.975199 174.71148,260.382572 C170.189871,266.232694 159.04042,270.206998 165.508597,271.964734 C169.911305,273.165797 171.113897,269.657073 172.91099,273.159049 C174.310616,275.891805 167.132435,282.345832 171.378874,282.345832 C175.625314,282.345832 182.514737,280.112395 181.234011,284.359975 C179.837782,288.982043 181.196643,292.163511 190.926085,286.85994 C201.124334,281.30671 222.543375,254.522328 222.543375,254.522328 L189.720096,237.589363 Z" id="Fill-202" fill="#F496A4" />
          <path d="M290.566579,4.79229427 C289.710497,5.05882232 288.715131,4.49540227 287.515937,5.48729146 C286.935024,5.96636719 287.390242,7.23490576 286.962201,7.6262634 C286.337125,8.19980477 284.869556,7.79157826 284.815201,8.52031318 C284.679315,10.1802094 286.051765,12.2820698 289.166953,15.0047044 C293.253726,18.5775298 299.103621,17.8757851 295.900107,37.683205 C294.320432,47.4603985 290.179304,60.1525316 290.179304,60.1525316 L332.966428,73.0066059 C332.966428,73.0066059 338.072347,47.794402 337.386122,33.1117429 C337.09057,26.7420599 345.056891,17.9972409 330.785457,10.6289212 C315.875358,2.93334548 307.800329,0.423258537 298.869218,0.419884764 C294.666941,0.419884764 297.867058,3.2032473 295.733647,2.96033566 C294.877564,2.86249625 294.16756,1.7660201 292.618459,2.46101729 C291.592519,2.91647661 291.738596,4.42792681 290.566579,4.79229427" id="Fill-204" fill="#F496A4" />
          <path d="M380.783375,141.88825 C380.783375,141.88825 422.439248,153.193762 433.636259,160.562082 C436.635944,162.542487 438.426243,165.292111 439.33668,168.503943 C440.960518,174.229235 438.670838,175.548381 440.236925,182.346533 C441.228893,186.664962 445.713133,196.442155 443.277376,201.759221 C443.039575,202.278782 442.013635,202.487956 441.636551,202.913052 C441.317219,203.270671 441.23229,204.056761 440.800852,204.33341 C439.700175,205.04865 439.45558,203.722757 439.170219,204.006154 C437.712841,205.443381 438.534952,206.590464 436.900922,206.458887 C436.255463,206.404906 436.065223,205.291561 435.497898,205.200469 C434.957751,205.109378 434.930574,207.12352 433.666834,207.12352 C432.375916,207.12352 431.947875,206.38129 431.373756,205.854981 C428.649241,203.371885 425.503478,198.847655 419.972916,194.485367 C406.530387,183.871478 380.783375,181.729132 380.783375,181.729132 L380.783375,141.88825 Z" id="Fill-206" fill="#F496A4" />
          <path d="M147.076671,125.724167 L140.43524,160.561745 C140.43524,160.561745 172.973158,164.262773 185.345584,183.53039 C204.125038,212.777626 189.721115,237.588351 189.721115,237.588351 L222.544394,254.521316 C222.544394,254.521316 253.288616,217.554888 293.378401,198.722488 C326.983025,182.943353 380.783715,181.728795 380.783715,181.728795 L380.783715,141.887912 C380.783715,141.887912 339.100665,133.143093 331.07999,119.553537 C327.166471,112.920699 328.735955,101.473488 330.315631,91.53098 C332.289376,79.1391126 334.334461,68.0191576 334.334461,68.0191576 L290.178285,60.1515195 C290.178285,60.1515195 281.056933,90.4986055 247.554224,104.56049 C214.051515,118.619002 214.051515,118.619002 191.987015,122.930683 C173.295887,126.584479 147.076671,125.724167 147.076671,125.724167" id="Fill-208" fill="#7ECCC6" />
        </g>
        <g transform="translate(248.422728, 61.762169)">
          <line x1="166.675551" y1="-84.4290897" x2="277.261741" y2="188.673255" id="Stroke-211" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="155.853953" y1="-80.0762" x2="266.436752" y2="193.026145" id="Stroke-214" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="145.032356" y1="-75.7233103" x2="255.615155" y2="197.379034" id="Stroke-217" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="134.207367" y1="-71.3704207" x2="244.793557" y2="201.731924" id="Stroke-220" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="123.385769" y1="-67.017869" x2="233.968568" y2="206.084476" id="Stroke-223" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="112.564172" y1="-62.6649793" x2="223.146971" y2="210.437366" id="Stroke-226" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="101.739183" y1="-58.3120897" x2="212.325373" y2="214.790255" id="Stroke-229" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="90.9175853" y1="-53.9625793" x2="201.500384" y2="219.139766" id="Stroke-232" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="80.0959876" y1="-49.6096897" x2="190.678787" y2="223.492655" id="Stroke-235" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="69.2713381" y1="-45.2568" x2="179.854137" y2="227.845545" id="Stroke-238" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="58.5687597" y1="-41.2208897" x2="169.151559" y2="231.881455" id="Stroke-241" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="47.7471619" y1="-36.868" x2="158.329961" y2="236.234345" id="Stroke-244" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="36.9221734" y1="-32.5184897" x2="147.508363" y2="240.587234" id="Stroke-247" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="26.1005756" y1="-28.1656" x2="136.683375" y2="244.936745" id="Stroke-250" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="15.2789779" y1="-23.8127103" x2="125.861777" y2="249.289634" id="Stroke-253" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="4.45398934" y1="-19.4598207" x2="115.040179" y2="253.642524" id="Stroke-256" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="-6.36760837" y1="-15.106931" x2="104.215191" y2="257.995414" id="Stroke-259" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="-17.1909015" y1="-10.7540414" x2="93.3952884" y2="262.348303" id="Stroke-262" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="-28.0124992" y1="-6.40148966" x2="82.5736906" y2="266.700855" id="Stroke-265" stroke="#FEFEFE" strokeWidth="0.25" />
          <line x1="-38.8357924" y1="-2.0486" x2="71.7470066" y2="271.053745" id="Stroke-268" stroke="#FEFEFE" strokeWidth="0.25" />
        </g>
        <g transform="translate(0.587942, 9.547508)">
          <path d="M398.161739,96.2259583 C398.161739,96.2259583 409.60549,96.8253763 415.63426,106.399133 C421.65962,115.976276 419.449981,121.760492 419.449981,121.760492" id="Stroke-300" stroke="#FEFEFE" strokeWidth="0.25" />
          <path d="M330.685888,77.0774288 C330.685888,77.0774288 320.834577,69.6338075 309.68749,72.2922436 C298.540403,74.9506798 295.171384,77.0774288 295.171384,77.0774288 C295.171384,77.0774288 282.019254,73.3522316 276.890707,77.0774288 C271.762161,80.8026259 263.728347,80.8026259 263.728347,80.8026259 L342.992353,80.8026259 L330.685888,77.0774288 Z" id="Fill-282" fill="#FEFEFE" />
          <path d="M294.215238,152.7521 C294.215238,152.7521 285.213002,162.708536 291.37476,173.057812 C297.533107,183.410473 311.742319,186.698806 317.426685,173.057812 C323.111052,159.416817 316.475312,145.149312 294.215238,152.7521" id="Fill-272" fill="#F496A4" />
          <path d="M310.755483,151.393081 C304.767632,149.513549 296.870216,148.13184 286.490365,152.751084 C275.912737,157.465152 271.026296,160.919426 276.88798,161.633986 C278.732756,161.860885 279.493172,161.644146 279.660259,163.449173 C279.834166,165.298226 276.976638,166.927153 275.807029,169.599135 C272.833563,176.416246 284.205706,174.888915 291.373396,173.056796 C298.541085,171.224676 303.996986,167.472386 308.18098,169.599135 C313.251557,172.176294 312.296775,175.1192 314.005153,172.921334 C315.171352,171.424482 315.212271,167.834746 316.41598,169.131792 C317.623098,170.428838 317.824284,172.115337 318.090259,171.282247 C318.356234,170.449158 320.77388,164.519321 319.351936,159.859438 C318.04934,155.568688 314.816719,152.669807 310.755483,151.393081" id="Fill-274" fill="#F06140" />
          <path d="M270.019342,162.643854 C270.019342,162.643854 266.875379,163.561607 266.26841,165.244718 C264.931714,168.966529 271.22646,165.244718 272.027795,164.57757 C272.83254,163.913807 272.365379,162.251015 270.019342,162.643854" id="Fill-276" fill="#EE3F2E" />
          <path d="M422.261134,132.198156 C422.261134,132.198156 432.920599,129.729366 438.94937,140.501959 C444.97132,151.274552 440.136028,162.125035 440.136028,162.125035" id="Stroke-302" stroke="#FEFEFE" strokeWidth="0.25" />
          <path d="M441.029091,3.00662385 C441.029091,3.00662385 436.971265,-0.535700016 427.740563,3.00662385 C418.506451,6.54556118 415.492066,2.30560948 414.087171,0.138222021" id="Stroke-304" stroke="#FEFEFE" strokeWidth="0.25" />
          <path d="M435.517949,20.696908 C435.517949,20.696908 444.042794,24.9097673 449.17475,20.1313553" id="Stroke-306" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M548.295507,160.722329 C548.295507,160.722329 550.501737,166.645393 544.077414,173.323655 C540.213954,177.333322 541.161917,182.098188 541.161917,182.098188" id="Stroke-308" stroke="#FEFEFE" strokeWidth="0.25" />
          <path d="M532.861105,157.93114 C532.861105,157.93114 533.727229,145.885207 541.058596,149.515581" id="Stroke-310" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M182.989543,111.931728 C198.586599,108.95157 220.819394,106.056076 242.506599,107.911902" id="Stroke-320" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M163.170643,117.636698 C163.170643,117.636698 167.71609,115.123884 176.967252,113.149529" id="Stroke-322" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M188.27597,104.543646 C188.27597,104.543646 205.751901,101.177423 225.836435,101.810706" id="Stroke-324" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <polygon id="Fill-326" fill="#EE3F2E" points="295.171043 293.101952 313.942752 292.637996 309.513242 278.431448" />
          <path d="M322.986589,278.771796 C314.69021,285.070766 309.687831,288.037377 309.687831,288.037377" id="Stroke-328" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M379.147584,222.424463 C379.147584,222.424463 367.154832,226.281736 351.830571,247.064949 C339.050124,264.404049 331.186807,272.707852 331.186807,272.707852" id="Stroke-330" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          <path d="M339.413624,251.414964 C339.413624,251.414964 325.855711,273.254779 305.167617,286.018659" id="Stroke-332" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
        </g>
      </g>
    </FigureBlue>
    <FigureRed
      viewBox="0 0 700 741"
      animate={ width >= 1024 && { x: -150, y: -350, rotate: 0 }}
      initial={{ rotate: -35 }}
      transition={{ duration: .8, delay: .6 }}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(350.000000, 370.500000) rotate(-16.000000) translate(-350.000000, -370.500000) translate(74.000000, 65.000000)">
        <g transform="translate(25.805473, 185.691525)">
          <path d="M37.4252004,97.9610495 C37.4252004,97.9610495 53.7209351,56.7002191 80.3474234,31.8832331 C100.428627,13.1693975 132.810917,-3.8793248 134.400004,0.922318528 C135.989091,5.72736005 132.679337,5.32637355 134.400004,6.79779009 C136.124046,8.26241024 139.831916,4.12681227 142.746929,6.3968036 C145.661943,8.66679492 136.387207,13.3359088 137.844713,15.068986 C139.30222,16.8020632 143.263129,14.2704112 143.283373,17.6074344 C143.320485,22.5416072 126.690738,22.3139284 114.926095,38.0203654 C103.930692,52.7005488 102.07507,70.8468866 102.07507,70.8468866 L37.4252004,97.9610495 Z" id="Fill-1" fill="#F496A4" />
          <path d="M163.414509,125.554697 C163.414509,125.554697 180.901216,121.949217 194.015402,109.542423 C207.132963,97.1322308 208.327308,90.7266415 211.505483,91.5252163 C214.683657,92.3305875 213.769342,96.4525927 215.479888,96.3302578 C218.870615,96.0957827 219.852408,90.2576912 224.619669,92.3305875 C229.390305,94.3966874 224.754624,99.4022221 226.738452,100.200797 C228.729028,100.999372 230.847811,98.6002491 232.170364,103.53782 C233.148783,107.184079 206.866428,135.562368 186.862823,153.576177 C170.729034,168.103442 159.834846,175.99404 159.834846,175.99404 L163.414509,125.554697 Z" id="Fill-3" fill="#F496A4" />
          <path d="M102.605778,79.1184224 C102.605778,79.1184224 104.194865,89.9280667 115.719964,102.334861 C127.248437,114.745053 155.464013,124.756122 155.464013,124.756122 C155.464013,124.756122 134.134482,140.731016 108.9655,133.727345 C72.3996258,123.553163 77.5683764,89.5270802 102.605778,79.1184224" id="Fill-5" fill="#F496A4" />
          <g transform="translate(3.373858, 272.324469)">
            <path d="M156.646887,128.526825 C152.878288,124.513561 143.721637,122.953792 136.714134,122.314932 C127.52037,121.475579 126.336146,109.534337 118.832686,105.507481 C116.858979,101.864621 109.493846,88.2548677 101.072696,72.0488967 C96.5652219,63.3733161 89.0077798,53.3418574 84.6453812,44.9619194 C81.8552006,39.6029728 80.9341373,34.5634561 80.0097002,29.0821747 C79.1561141,24.0392598 78.9030747,18.8230372 78.9874212,13.8548826 C78.5825582,13.9840138 78.2991541,14.0723668 78.2991541,14.0723668 C78.2991541,14.0723668 69.1762418,5.72980882 35.8897576,1.32575379 C22.920647,-0.390332464 0.406891817,1.685962 0.244946628,1.70295295 C3.95956441,24.8106491 13.2747867,51.1738118 34.4491202,73.3979784 C84.7904571,126.239842 111.5519,131.846857 111.5519,131.846857 L111.592386,131.717726 C115.576912,139.013641 121.649857,147.505719 129.291646,149.942222 C136.778237,152.33115 139.774223,151.094208 142.803947,150.193688 C143.316774,150.040769 148.451786,149.619394 149.460569,148.137783 C150.553699,146.540633 148.633974,145.446416 149.01522,144.885714 C149.281755,144.491524 153.337132,143.951212 154.528104,143.139044 C155.769684,142.286098 153.711631,139.856392 153.981539,139.336469 C154.325673,138.67722 158.138133,139.737455 159.049074,137.983989 C159.595639,136.933948 157.746765,135.680016 157.918832,135.18388 C158.428284,133.743047 163.205667,134.296952 161.086885,130.725454 C160.54032,129.807942 157.419501,129.349187 156.646887,128.526825" id="Fill-7" fill="#F496A4" mask="url(#mask-2)" />
          </g>
          <path d="M159.836195,175.993361 L165.942879,124.911759 L158.247108,124.354456 C158.247108,124.354456 125.497067,145.052835 98.23292,127.154565 C70.9721464,109.259693 102.60544,79.1177428 102.60544,79.1177428 L102.075744,70.8465468 L37.4258752,97.9607097 C37.4258752,97.9607097 27.3549087,126.889506 24.1767344,143.968812 C22.2873738,154.119208 13.81899,176.645813 7.86750427,194.792151 C-6.441028,238.424918 3.60294761,274.027761 3.60294761,274.027761 L3.6198169,274.027761 C3.78176209,274.01077 26.2955173,271.934476 39.2646279,273.650562 C72.551112,278.054617 81.6740244,286.397175 81.6740244,286.397175 C81.6740244,286.397175 81.9574285,286.308822 82.3622914,286.179691 C86.2658453,284.932555 107.281607,278.23812 113.631208,276.855056 C122.592176,274.901096 145.534411,272.474788 145.561402,272.47139 L145.564776,272.47139 C145.564776,272.47139 154.127627,226.415713 139.252287,201.61232 C133.277184,191.652223 159.836195,175.993361 159.836195,175.993361" id="Fill-10" fill="#F06140" />
          <path d="M172.14403,103.572821 C170.2378,107.236071 167.552209,107.531714 168.719564,109.533248 C169.893666,111.534782 174.131232,109.798307 175.059043,105.927768 C175.986854,102.060627 173.361993,101.238265 172.14403,103.572821" id="Fill-12" fill="#EE3F2E" />
        </g>
        <g transform="translate(25.871221, 256.300744)">
          <path d="M130.414811,240.970812 C130.414811,240.970812 126.892166,238.257641 125.154599,240.984327 C123.413638,243.707634 128.331089,245.326076 128.331089,245.326076 C128.331089,245.326076 125.972477,247.255367 128.806205,249.43131 C131.690837,251.641041 134.086779,249.877311 134.086779,249.877311 C134.086779,249.877311 133.964606,252.090421 136.896751,252.37086 C138.94993,252.570209 139.112827,250.171266 139.112827,250.171266 C139.112827,250.171266 143.212399,252.603997 144.495212,249.630659 C145.506531,247.285776 139.794958,245.34297 139.794958,245.34297 C139.794958,245.34297 141.15922,243.362997 139.418259,241.997965 C137.677298,240.626175 135.430679,242.024995 135.430679,242.024995 C135.430679,242.024995 134.090173,237.176427 130.414811,240.970812 Z" id="Stroke-15" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-4)" />
          <path d="M139.651405,217.177349 C139.651405,217.177349 136.12876,214.464178 134.391193,217.190864 C132.650232,219.914171 137.567682,221.532613 137.567682,221.532613 C137.567682,221.532613 135.205677,223.458526 138.042798,225.634469 C140.927431,227.847578 143.323373,226.083848 143.323373,226.083848 C143.323373,226.083848 143.2012,228.296958 146.129951,228.577398 C148.186524,228.776746 148.349421,226.377803 148.349421,226.377803 C148.349421,226.377803 152.448993,228.810534 153.731806,225.837196 C154.743124,223.492314 149.031552,221.549507 149.031552,221.549507 C149.031552,221.549507 150.395813,219.569535 148.654853,218.201123 C146.913892,216.832712 144.663878,218.231532 144.663878,218.231532 C144.663878,218.231532 143.323373,213.382964 139.651405,217.177349 Z" id="Stroke-18" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-6)" />
          <path d="M148.886302,193.383886 C148.886302,193.383886 145.363656,190.670715 143.626089,193.394022 C141.885129,196.120709 146.802579,197.739151 146.802579,197.739151 C146.802579,197.739151 144.443968,199.665063 147.277695,201.841006 C150.165721,204.054115 152.558269,202.290385 152.558269,202.290385 C152.558269,202.290385 152.436097,204.503495 155.368241,204.783935 C157.421421,204.979905 157.584318,202.58434 157.584318,202.58434 C157.584318,202.58434 161.68389,205.017072 162.966703,202.043733 C163.978021,199.698851 158.266448,197.756045 158.266448,197.756045 C158.266448,197.756045 159.63071,195.776072 157.889749,194.40766 C156.148789,193.03587 153.902169,194.43807 153.902169,194.43807 C153.902169,194.43807 152.561663,189.589501 148.886302,193.383886 Z" id="Stroke-21" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-8)" />
          <path d="M158.122556,169.590761 C158.122556,169.590761 154.599911,166.87759 152.862344,169.600897 C151.121383,172.327584 156.038833,173.946026 156.038833,173.946026 C156.038833,173.946026 153.680222,175.871938 156.513949,178.047881 C159.398582,180.260991 161.794524,178.49726 161.794524,178.49726 C161.794524,178.49726 161.672351,180.71037 164.604495,180.99081 C166.657675,181.18678 166.820572,178.791215 166.820572,178.791215 C166.820572,178.791215 170.920144,181.223947 172.202957,178.250608 C173.214275,175.905726 167.502703,173.96292 167.502703,173.96292 C167.502703,173.96292 168.866964,171.982947 167.126004,170.614535 C165.385043,169.242745 163.138423,170.644945 163.138423,170.644945 C163.138423,170.644945 161.794524,165.796376 158.122556,169.590761 Z" id="Stroke-24" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-10)" />
          <path d="M167.357453,145.79392 C167.357453,145.79392 163.834807,143.084127 162.09724,145.807435 C160.35628,148.534121 165.27373,150.152563 165.27373,150.152563 C165.27373,150.152563 162.915119,152.078475 165.748846,154.254418 C168.636872,156.467528 171.02942,154.703798 171.02942,154.703798 C171.02942,154.703798 170.907248,156.916907 173.839392,157.197347 C175.892572,157.393317 176.055469,154.997753 176.055469,154.997753 C176.055469,154.997753 180.155041,157.430484 181.437854,154.453767 C182.449172,152.112263 176.737599,150.169457 176.737599,150.169457 C176.737599,150.169457 178.101861,148.189484 176.3609,146.821073 C174.61994,145.449283 172.37332,146.848103 172.37332,146.848103 C172.37332,146.848103 171.032814,142.002913 167.357453,145.79392 Z" id="Stroke-27" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-12)" />
          <path d="M176.594046,122.000457 C176.594046,122.000457 173.071401,119.287286 171.333834,122.013972 C169.592873,124.737279 174.510324,126.3591 174.510324,126.3591 C174.510324,126.3591 172.151712,128.285012 174.98544,130.460955 C177.870072,132.670686 180.266014,130.906956 180.266014,130.906956 C180.266014,130.906956 180.143841,133.120066 183.075986,133.400506 C185.129165,133.599854 185.292062,131.200911 185.292062,131.200911 C185.292062,131.200911 189.391634,133.637021 190.674447,130.660304 C191.685766,128.315422 185.974193,126.372615 185.974193,126.372615 C185.974193,126.372615 187.338455,124.392642 185.597494,123.02761 C183.856533,121.65582 181.609914,123.05464 181.609914,123.05464 C181.609914,123.05464 180.269408,118.206072 176.594046,122.000457 Z" id="Stroke-30" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-14)" />
          <path d="M185.828943,98.2069939 C185.828943,98.2069939 182.306298,95.4938229 180.568731,98.2205091 C178.82777,100.943817 183.74522,102.562259 183.74522,102.562259 C183.74522,102.562259 181.386609,104.49155 184.220336,106.667493 C187.108363,108.877223 189.500911,107.113493 189.500911,107.113493 C189.500911,107.113493 189.378738,109.326603 192.310882,109.607043 C194.364062,109.806392 194.526959,107.407448 194.526959,107.407448 C194.526959,107.407448 198.626531,109.840179 199.909344,106.866841 C200.920662,104.521959 195.20909,102.579153 195.20909,102.579153 C195.20909,102.579153 196.573351,100.59918 194.832391,99.2341471 C193.09143,97.862357 190.84481,99.2611775 190.84481,99.2611775 C190.84481,99.2611775 189.504305,94.412609 185.828943,98.2069939 Z" id="Stroke-33" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-16)" />
          <path d="M195.065537,74.4135312 C195.065537,74.4135312 191.542891,71.7003601 189.805324,74.4270463 C188.064364,77.1503538 192.981814,78.7687958 192.981814,78.7687958 C192.981814,78.7687958 190.623203,80.694708 193.45693,82.8706509 C196.341563,85.0837606 198.737504,83.3200304 198.737504,83.3200304 C198.737504,83.3200304 198.615332,85.5331401 201.547476,85.8135799 C203.600656,86.0129287 203.763553,83.6139855 203.763553,83.6139855 C203.763553,83.6139855 207.863125,86.0467167 209.145938,83.0733785 C210.157256,80.7284959 204.445683,78.7856898 204.445683,78.7856898 C204.445683,78.7856898 205.809945,76.8057169 204.068984,75.4373055 C202.328024,74.0688942 200.081404,75.4677147 200.081404,75.4677147 C200.081404,75.4677147 198.740898,70.6191462 195.065537,74.4135312 Z" id="Stroke-36" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-18)" />
          <path d="M204.30213,50.6200684 C204.30213,50.6200684 200.779485,47.9068973 199.041918,50.6302048 C197.300957,53.356891 202.218408,54.975333 202.218408,54.975333 C202.218408,54.975333 199.856403,56.9012452 202.693524,59.0771881 C205.578156,61.2902978 207.974098,59.5265676 207.974098,59.5265676 C207.974098,59.5265676 207.851925,61.7396773 210.780676,62.0201171 C212.837249,62.2160872 213.000146,59.8205227 213.000146,59.8205227 C213.000146,59.8205227 217.099718,62.2532539 218.382531,59.2799157 C219.393849,56.9350331 213.682277,54.992227 213.682277,54.992227 C213.682277,54.992227 215.046538,53.0122541 213.305578,51.6438428 C211.564617,50.2720527 209.314604,51.6742519 209.314604,51.6742519 C209.314604,51.6742519 207.974098,46.8256834 204.30213,50.6200684 Z" id="Stroke-39" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-20)" />
          <path d="M213.537027,26.8266056 C213.537027,26.8266056 210.014382,24.1134345 208.276815,26.836742 C206.535854,29.5634282 211.453304,31.1818702 211.453304,31.1818702 C211.453304,31.1818702 209.094693,33.1077824 211.92842,35.2837253 C214.816447,37.496835 217.208995,35.7331049 217.208995,35.7331049 C217.208995,35.7331049 217.086822,37.9462145 220.018966,38.2266544 C222.072146,38.4226244 222.235043,36.0270599 222.235043,36.0270599 C222.235043,36.0270599 226.334615,38.4597911 227.617428,35.4830742 C228.628746,33.1415704 222.917174,31.1987642 222.917174,31.1987642 C222.917174,31.1987642 224.281435,29.2187913 222.540475,27.85038 C220.799514,26.4785899 218.552894,27.8807891 218.552894,27.8807891 C218.552894,27.8807891 217.212389,23.0322206 213.537027,26.8266056 Z" id="Stroke-42" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-22)" />
          <path d="M96.8044298,241.71347 C96.8044298,241.71347 93.2817844,239.000299 91.5442174,241.726986 C89.8032568,244.450293 94.720707,246.068735 94.720707,246.068735 C94.720707,246.068735 92.3620956,247.998026 95.195823,250.173969 C98.0838492,252.3837 100.476397,250.61997 100.476397,250.61997 C100.476397,250.61997 100.354225,252.833079 103.286369,253.113519 C105.339549,253.312868 105.502446,250.913925 105.502446,250.913925 C105.502446,250.913925 109.602018,253.346656 110.884831,250.373318 C111.896149,248.028435 106.184576,246.085629 106.184576,246.085629 C106.184576,246.085629 107.548838,244.105656 105.807877,242.740624 C104.066917,241.368833 101.820297,242.767654 101.820297,242.767654 C101.820297,242.767654 100.479791,237.919085 96.8044298,241.71347 Z" id="Stroke-45" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-24)" />
          <path d="M106.041023,217.920008 C106.041023,217.920008 102.518378,215.206837 100.780811,217.933523 C99.0398504,220.65683 103.957301,222.275272 103.957301,222.275272 C103.957301,222.275272 101.598689,224.201184 104.432417,226.377127 C107.320443,228.590237 109.712991,226.826507 109.712991,226.826507 C109.712991,226.826507 109.590818,229.039617 112.522963,229.320056 C114.576142,229.519405 114.739039,227.120462 114.739039,227.120462 C114.739039,227.120462 118.838611,229.553193 120.121424,226.579855 C121.132743,224.234972 115.42117,222.292166 115.42117,222.292166 C115.42117,222.292166 116.785431,220.312193 115.044471,218.943782 C113.30351,217.575371 111.05689,218.974191 111.05689,218.974191 C111.05689,218.974191 109.716385,214.125623 106.041023,217.920008 Z" id="Stroke-48" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-26)" />
          <path d="M115.277278,194.126545 C115.277278,194.126545 111.754632,191.413374 110.017065,194.136681 C108.276105,196.863367 113.193555,198.481809 113.193555,198.481809 C113.193555,198.481809 110.834943,200.407722 113.668671,202.583665 C116.553303,204.796774 118.949245,203.033044 118.949245,203.033044 C118.949245,203.033044 118.827073,205.246154 121.755823,205.526594 C123.812397,205.722564 123.975293,203.326999 123.975293,203.326999 C123.975293,203.326999 128.074865,205.75973 129.357678,202.786392 C130.368997,200.44151 124.657424,198.498703 124.657424,198.498703 C124.657424,198.498703 126.021686,196.51873 124.280725,195.150319 C122.539764,193.778529 120.289751,195.180728 120.289751,195.180728 C120.289751,195.180728 118.949245,190.33216 115.277278,194.126545 Z" id="Stroke-51" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-28)" />
          <path d="M124.512174,170.333082 C124.512174,170.333082 120.989529,167.619911 119.251962,170.343218 C117.511001,173.069905 122.428452,174.688347 122.428452,174.688347 C122.428452,174.688347 120.06984,176.614259 122.903568,178.790202 C125.791594,181.003311 128.184142,179.239581 128.184142,179.239581 C128.184142,179.239581 128.061969,181.452691 130.994114,181.733131 C133.047293,181.929101 133.21019,179.533536 133.21019,179.533536 C133.21019,179.533536 137.309762,181.966268 138.592575,178.992929 C139.603894,176.648047 133.892321,174.705241 133.892321,174.705241 C133.892321,174.705241 135.256582,172.725268 133.515622,171.356856 C131.774661,169.985066 129.528041,171.387266 129.528041,171.387266 C129.528041,171.387266 128.187536,166.538697 124.512174,170.333082 Z" id="Stroke-54" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-30)" />
          <path d="M133.748768,146.53624 C133.748768,146.53624 130.226123,143.823069 128.488556,146.549756 C126.747595,149.276442 131.665045,150.894884 131.665045,150.894884 C131.665045,150.894884 129.306434,152.820796 132.140161,154.996739 C135.024794,157.209849 137.420736,155.446118 137.420736,155.446118 C137.420736,155.446118 137.298563,157.659228 140.230707,157.939668 C142.283887,158.135638 142.446784,155.740074 142.446784,155.740074 C142.446784,155.740074 146.546356,158.172805 147.829169,155.196088 C148.840487,152.851205 143.128915,150.911778 143.128915,150.911778 C143.128915,150.911778 144.493176,148.931805 142.752215,147.563394 C141.011255,146.191604 138.764635,147.590424 138.764635,147.590424 C138.764635,147.590424 137.420736,142.745234 133.748768,146.53624 Z" id="Stroke-57" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-32)" />
          <path d="M142.983665,122.742778 C142.983665,122.742778 139.461019,120.029607 137.723452,122.756293 C135.982492,125.4796 140.899942,127.098042 140.899942,127.098042 C140.899942,127.098042 138.541331,129.027333 141.375058,131.203276 C144.263084,133.413007 146.655632,131.649277 146.655632,131.649277 C146.655632,131.649277 146.53346,133.862387 149.465604,134.142826 C151.518784,134.342175 151.681681,131.943232 151.681681,131.943232 C151.681681,131.943232 155.781253,134.375963 157.064066,131.402625 C158.075384,129.057742 152.363811,127.114936 152.363811,127.114936 C152.363811,127.114936 153.728073,125.134963 151.987112,123.769931 C150.246152,122.398141 147.999532,123.796961 147.999532,123.796961 C147.999532,123.796961 146.659026,118.948393 142.983665,122.742778 Z" id="Stroke-60" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-34)" />
          <path d="M152.220258,98.9493148 C152.220258,98.9493148 148.697613,96.2361438 146.960046,98.96283 C145.219085,101.686137 150.136536,103.304579 150.136536,103.304579 C150.136536,103.304579 147.777924,105.23387 150.611652,107.406435 C153.496284,109.619544 155.892226,107.855814 155.892226,107.855814 C155.892226,107.855814 155.770053,110.068924 158.702198,110.349364 C160.755377,110.548712 160.918274,108.149769 160.918274,108.149769 C160.918274,108.149769 165.017846,110.5825 166.300659,107.609162 C167.311977,105.26428 161.600405,103.321473 161.600405,103.321473 C161.600405,103.321473 162.964666,101.341501 161.223706,99.9730892 C159.482745,98.6046779 157.236125,100.003498 157.236125,100.003498 C157.236125,100.003498 155.89562,95.1549299 152.220258,98.9493148 Z" id="Stroke-63" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-36)" />
          <path d="M161.455155,75.1558521 C161.455155,75.1558521 157.93251,72.442681 156.194943,75.1659884 C154.453982,77.8926747 159.371432,79.5111167 159.371432,79.5111167 C159.371432,79.5111167 157.012821,81.4370289 159.846548,83.6129718 C162.734575,85.8260815 165.127123,84.0623513 165.127123,84.0623513 C165.127123,84.0623513 165.00495,86.275461 167.937094,86.5559008 C169.990274,86.7552496 170.153171,84.3563064 170.153171,84.3563064 C170.153171,84.3563064 174.252743,86.7890376 175.535556,83.8156994 C176.546874,81.4708168 170.835302,79.5280107 170.835302,79.5280107 C170.835302,79.5280107 172.199563,77.5480378 170.458603,76.1796264 C168.717642,74.8112151 166.471022,76.2100356 166.471022,76.2100356 C166.471022,76.2100356 165.130516,71.3614671 161.455155,75.1558521 Z" id="Stroke-66" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-38)" />
          <path d="M170.691409,51.3623893 C170.691409,51.3623893 167.168764,48.6492182 165.431197,51.3725257 C163.690236,54.0992119 168.607687,55.7176539 168.607687,55.7176539 C168.607687,55.7176539 166.249075,57.6435661 169.082803,59.819509 C171.970829,62.0326187 174.363377,60.2688885 174.363377,60.2688885 C174.363377,60.2688885 174.241204,62.4819982 177.173349,62.762438 C179.226528,62.9584081 179.389425,60.5628436 179.389425,60.5628436 C179.389425,60.5628436 183.488997,62.9955748 184.77181,60.0222366 C185.783128,57.677354 180.071556,55.7345479 180.071556,55.7345479 C180.071556,55.7345479 181.435817,53.754575 179.694857,52.3861637 C177.953896,51.0143736 175.707276,52.4165728 175.707276,52.4165728 C175.707276,52.4165728 174.366771,47.5680043 170.691409,51.3623893 Z" id="Stroke-69" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-40)" />
          <path d="M179.928003,27.5692644 C179.928003,27.5692644 176.405358,24.8560933 174.667791,27.5794007 C172.92683,30.306087 177.84428,31.924529 177.84428,31.924529 C177.84428,31.924529 175.485669,33.8504412 178.319396,36.0263841 C181.204029,38.2394938 183.599971,36.4757636 183.599971,36.4757636 C183.599971,36.4757636 183.477798,38.6888733 186.406548,38.9693131 C188.463122,39.1652831 188.626019,36.7697187 188.626019,36.7697187 C188.626019,36.7697187 192.725591,39.2024499 194.008404,36.2257329 C195.019722,33.8842291 189.30815,31.941423 189.30815,31.941423 C189.30815,31.941423 190.672411,29.9614501 188.93145,28.5930387 C187.19049,27.2212486 184.940476,28.6234479 184.940476,28.6234479 C184.940476,28.6234479 183.599971,23.7748794 179.928003,27.5692644 Z" id="Stroke-72" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-42)" />
          <path d="M73.2987465,218.995478 C73.2987465,218.995478 69.7761011,216.282306 68.0385341,219.008993 C66.2975735,221.7323 71.2150237,223.350742 71.2150237,223.350742 C71.2150237,223.350742 68.8564123,225.280033 71.6901396,227.455976 C74.5781659,229.665707 76.9707141,227.901977 76.9707141,227.901977 C76.9707141,227.901977 76.8485415,230.115086 79.7806857,230.395526 C81.8338654,230.594875 81.9967623,228.195932 81.9967623,228.195932 C81.9967623,228.195932 86.0963342,230.628663 87.3791473,227.655325 C88.3904656,225.310442 82.678893,223.367636 82.678893,223.367636 C82.678893,223.367636 84.0431546,221.387663 82.3021939,220.019252 C80.5612333,218.650841 78.3146136,220.049661 78.3146136,220.049661 C78.3146136,220.049661 76.9741078,215.201093 73.2987465,218.995478 Z" id="Stroke-75" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-44)" />
          <path d="M82.5353401,195.202015 C82.5353401,195.202015 79.0126947,192.488844 77.2751277,195.21553 C75.5341671,197.938837 80.4516173,199.557279 80.4516173,199.557279 C80.4516173,199.557279 78.0930059,201.483192 80.9267332,203.659134 C83.8113658,205.872244 86.2073077,204.108514 86.2073077,204.108514 C86.2073077,204.108514 86.0851351,206.321624 89.0172793,206.602063 C91.070459,206.801412 91.2333559,204.402469 91.2333559,204.402469 C91.2333559,204.402469 95.3329278,206.8352 96.6157409,203.861862 C97.6270592,201.516979 91.9154866,199.574173 91.9154866,199.574173 C91.9154866,199.574173 93.2797482,197.5942 91.5387875,196.225789 C89.7978269,194.857378 87.5512072,196.256198 87.5512072,196.256198 C87.5512072,196.256198 86.2073077,191.40763 82.5353401,195.202015 Z" id="Stroke-78" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-46)" />
          <path d="M91.7702369,171.408552 C91.7702369,171.408552 88.2475914,168.695381 86.5100245,171.418688 C84.7690639,174.145375 89.686514,175.763817 89.686514,175.763817 C89.686514,175.763817 87.3279027,177.689729 90.16163,179.865672 C93.0496563,182.078781 95.4422045,180.315051 95.4422045,180.315051 C95.4422045,180.315051 95.3200318,182.528161 98.252176,182.808601 C100.305356,183.004571 100.468253,180.609006 100.468253,180.609006 C100.468253,180.609006 104.567825,183.041737 105.850638,180.068399 C106.861956,177.723517 101.150383,175.780711 101.150383,175.780711 C101.150383,175.780711 102.514645,173.800738 100.773684,172.432326 C99.0327237,171.060536 96.7861039,172.462735 96.7861039,172.462735 C96.7861039,172.462735 95.4455982,167.614167 91.7702369,171.408552 Z" id="Stroke-81" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-48)" />
          <path d="M101.00683,147.615427 C101.00683,147.615427 97.484185,144.902256 95.7466181,147.625563 C94.0056575,150.35225 98.9231076,151.970692 98.9231076,151.970692 C98.9231076,151.970692 96.5644963,153.896604 99.3982236,156.072547 C102.282856,158.285656 104.678798,156.521926 104.678798,156.521926 C104.678798,156.521926 104.556625,158.735036 107.48877,159.015476 C109.541949,159.211446 109.704846,156.815881 109.704846,156.815881 C109.704846,156.815881 113.804418,159.248613 115.087231,156.271896 C116.09855,153.930392 110.386977,151.987586 110.386977,151.987586 C110.386977,151.987586 111.751239,150.007613 110.010278,148.639201 C108.269317,147.267411 106.022698,148.669611 106.022698,148.669611 C106.022698,148.669611 104.682192,143.821042 101.00683,147.615427 Z" id="Stroke-84" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-50)" />
          <path d="M110.241388,123.818585 C110.241388,123.818585 106.718742,121.105414 104.981175,123.832101 C103.240215,126.558787 108.157665,128.177229 108.157665,128.177229 C108.157665,128.177229 105.799054,130.103141 108.632781,132.279084 C111.520807,134.488815 113.913356,132.725085 113.913356,132.725085 C113.913356,132.725085 113.791183,134.941573 116.723327,135.218634 C118.776507,135.417983 118.939404,133.022419 118.939404,133.022419 C118.939404,133.022419 123.038976,135.45515 124.321789,132.478433 C125.333107,130.13355 119.621534,128.190744 119.621534,128.190744 C119.621534,128.190744 120.985796,126.21415 119.244835,124.845739 C117.503875,123.473949 115.257255,124.872769 115.257255,124.872769 C115.257255,124.872769 113.916749,120.024201 110.241388,123.818585 Z" id="Stroke-87" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-52)" />
          <path d="M119.477981,100.025123 C119.477981,100.025123 115.955336,97.3119516 114.217769,100.038638 C112.476808,102.761945 117.394259,104.380387 117.394259,104.380387 C117.394259,104.380387 115.035647,106.309678 117.869375,108.485621 C120.754007,110.695352 123.149949,108.931622 123.149949,108.931622 C123.149949,108.931622 123.027776,111.144732 125.959921,111.425171 C128.0131,111.62452 128.175997,109.225577 128.175997,109.225577 C128.175997,109.225577 132.275569,111.658308 133.558382,108.68497 C134.569701,106.340087 128.858128,104.397281 128.858128,104.397281 C128.858128,104.397281 130.22239,102.417308 128.481429,101.052276 C126.740468,99.6804857 124.493849,101.079306 124.493849,101.079306 C124.493849,101.079306 123.153343,96.2307377 119.477981,100.025123 Z" id="Stroke-90" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-54)" />
          <path d="M128.714575,76.2316599 C128.714575,76.2316599 125.19193,73.5184888 123.454363,76.245175 C121.713402,78.9684825 126.630852,80.5869245 126.630852,80.5869245 C126.630852,80.5869245 124.268847,82.5128367 127.105968,84.6887796 C129.990601,86.9018893 132.386543,85.1381591 132.386543,85.1381591 C132.386543,85.1381591 132.26437,87.3512688 135.193121,87.6317086 C137.249694,87.8310574 137.412591,85.4321142 137.412591,85.4321142 C137.412591,85.4321142 141.512163,87.8648454 142.794976,84.8915072 C143.806294,82.5466246 138.094722,80.6038185 138.094722,80.6038185 C138.094722,80.6038185 139.458983,78.6238456 137.718023,77.2554342 C135.977062,75.8870229 133.727048,77.2858434 133.727048,77.2858434 C133.727048,77.2858434 132.386543,72.4372749 128.714575,76.2316599 Z" id="Stroke-93" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-56)" />
          <path d="M137.949472,52.4381971 C137.949472,52.4381971 134.426826,49.725026 132.689259,52.4483335 C130.948299,55.1750197 135.865749,56.7934617 135.865749,56.7934617 C135.865749,56.7934617 133.507138,58.7193739 136.340865,60.8953168 C139.228891,63.1084265 141.621439,61.3446964 141.621439,61.3446964 C141.621439,61.3446964 141.499267,63.557806 144.431411,63.8382458 C146.484591,64.0342159 146.647488,61.6386514 146.647488,61.6386514 C146.647488,61.6386514 150.74706,64.0713826 152.029873,61.0980444 C153.041191,58.7531618 147.329618,56.8103557 147.329618,56.8103557 C147.329618,56.8103557 148.69388,54.8303828 146.952919,53.4619715 C145.211959,52.0901814 142.965339,53.4923806 142.965339,53.4923806 C142.965339,53.4923806 141.624833,48.6438121 137.949472,52.4381971 Z" id="Stroke-96" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-58)" />
          <path d="M147.186065,28.6447343 C147.186065,28.6447343 143.66342,25.9315632 141.925853,28.6548707 C140.184892,31.3815569 145.102343,32.9999989 145.102343,32.9999989 C145.102343,32.9999989 142.743731,34.9259111 145.577459,37.101854 C148.462091,39.3149637 150.858033,37.5512336 150.858033,37.5512336 C150.858033,37.5512336 150.73586,39.7643432 153.668005,40.0447831 C155.721184,40.2407531 155.884081,37.8451886 155.884081,37.8451886 C155.884081,37.8451886 159.983653,40.2779198 161.266466,37.3045816 C162.277785,34.9596991 156.566212,33.0168929 156.566212,33.0168929 C156.566212,33.0168929 157.930474,31.03692 156.189513,29.6685087 C154.448552,28.2967186 152.201933,29.6989178 152.201933,29.6989178 C152.201933,29.6989178 150.858033,24.8503493 147.186065,28.6447343 Z" id="Stroke-99" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-60)" />
          <path d="M156.420962,4.8478927 C156.420962,4.8478927 152.898317,2.13472164 151.16075,4.86140788 C149.419789,7.58809411 154.337239,9.20653613 154.337239,9.20653613 C154.337239,9.20653613 151.978628,11.1324483 154.812355,13.3083913 C157.700382,15.5215009 160.09293,13.7577708 160.09293,13.7577708 C160.09293,13.7577708 159.970757,15.9708804 162.902901,16.2513203 C164.956081,16.4472903 165.118978,14.0517258 165.118978,14.0517258 C165.118978,14.0517258 169.21855,16.484457 170.501363,13.5077401 C171.512681,11.1628575 165.801109,9.22343009 165.801109,9.22343009 C165.801109,9.22343009 167.16537,7.24345719 165.42441,5.87504588 C163.683449,4.50325578 161.436829,5.90207623 161.436829,5.90207623 C161.436829,5.90207623 160.096324,1.05688656 156.420962,4.8478927 Z" id="Stroke-102" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-62)" />
          <path d="M39.6900617,219.738136 C39.6900617,219.738136 36.1674162,217.024965 34.4298493,219.751651 C32.6888887,222.474959 37.6063389,224.093401 37.6063389,224.093401 C37.6063389,224.093401 35.2477275,226.022692 38.0814548,228.195256 C40.9660874,230.408366 43.3620293,228.644636 43.3620293,228.644636 C43.3620293,228.644636 43.2398567,230.857745 46.1720009,231.138185 C48.2251805,231.337534 48.3880774,228.938591 48.3880774,228.938591 C48.3880774,228.938591 52.4876494,231.371322 53.7704625,228.397984 C54.7817808,226.053101 49.0702082,224.110295 49.0702082,224.110295 C49.0702082,224.110295 50.4344697,222.130322 48.6935091,220.761911 C46.9525485,219.393499 44.7025351,220.79232 44.7025351,220.79232 C44.7025351,220.79232 43.3620293,215.943751 39.6900617,219.738136 Z" id="Stroke-105" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-64)" />
          <path d="M48.9249585,195.944674 C48.9249585,195.944674 45.402313,193.231502 43.6647461,195.95481 C41.9237855,198.681496 46.8412356,200.299938 46.8412356,200.299938 C46.8412356,200.299938 44.4826243,202.22585 47.3163516,204.401793 C50.2043779,206.614903 52.5969261,204.851173 52.5969261,204.851173 C52.5969261,204.851173 52.4747534,207.064282 55.4068976,207.344722 C57.4600773,207.544071 57.6229742,205.145128 57.6229742,205.145128 C57.6229742,205.145128 61.7225462,207.577859 63.0053593,204.604521 C64.0166775,202.259638 58.305105,200.316832 58.305105,200.316832 C58.305105,200.316832 59.6693665,198.336859 57.9284059,196.968448 C56.1874453,195.600037 53.9408255,196.998857 53.9408255,196.998857 C53.9408255,196.998857 52.6003198,192.150289 48.9249585,195.944674 Z" id="Stroke-108" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-66)" />
          <path d="M58.1615521,172.151211 C58.1615521,172.151211 54.6389066,169.43804 52.9013397,172.161347 C51.1603791,174.888033 56.0778292,176.506475 56.0778292,176.506475 C56.0778292,176.506475 53.7192179,178.432388 56.5529452,180.60833 C59.4375778,182.82144 61.8335197,181.05771 61.8335197,181.05771 C61.8335197,181.05771 61.711347,183.27082 64.6434912,183.551259 C66.6966709,183.747229 66.8595678,181.351665 66.8595678,181.351665 C66.8595678,181.351665 70.9591398,183.784396 72.2419529,180.811058 C73.2532711,178.466175 67.5416986,176.523369 67.5416986,176.523369 C67.5416986,176.523369 68.9059601,174.543396 67.1649995,173.174985 C65.4240389,171.803195 63.1774191,173.205394 63.1774191,173.205394 C63.1774191,173.205394 61.8369134,168.356826 58.1615521,172.151211 Z" id="Stroke-111" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-68)" />
          <path d="M67.3961095,148.357748 C67.3961095,148.357748 63.873464,145.644577 62.1358971,148.367884 C60.3949365,151.094571 65.3123866,152.713013 65.3123866,152.713013 C65.3123866,152.713013 62.9537753,154.638925 65.7875026,156.814868 C68.6755289,159.027977 71.0680771,157.264247 71.0680771,157.264247 C71.0680771,157.264247 70.9459044,159.477357 73.8780486,159.757797 C75.9312283,159.953767 76.0941252,157.558202 76.0941252,157.558202 C76.0941252,157.558202 80.1936972,159.990933 81.4765103,157.014216 C82.4878285,154.672713 76.776256,152.729907 76.776256,152.729907 C76.776256,152.729907 78.1405175,150.749934 76.3995569,149.381522 C74.6585963,148.009732 72.4119765,149.411931 72.4119765,149.411931 C72.4119765,149.411931 71.0714708,144.563363 67.3961095,148.357748 Z" id="Stroke-114" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-70)" />
          <path d="M76.6327031,124.560906 C76.6327031,124.560906 73.1100576,121.847735 71.3724907,124.574422 C69.6315301,127.297729 74.5489802,128.91955 74.5489802,128.91955 C74.5489802,128.91955 72.1903689,130.845462 75.0240962,133.021405 C77.9087288,135.231136 80.3046707,133.467406 80.3046707,133.467406 C80.3046707,133.467406 80.182498,135.680515 83.1146422,135.960955 C85.1678219,136.160304 85.3307188,133.761361 85.3307188,133.761361 C85.3307188,133.761361 89.4302908,136.197471 90.7131039,133.220754 C91.7244221,130.875871 86.0128496,128.933065 86.0128496,128.933065 C86.0128496,128.933065 87.3771111,126.953092 85.6361505,125.58806 C83.8951899,124.216269 81.6485701,125.61509 81.6485701,125.61509 C81.6485701,125.61509 80.3080644,120.766521 76.6327031,124.560906 Z" id="Stroke-117" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-72)" />
          <path d="M85.8675998,100.767444 C85.8675998,100.767444 82.3449544,98.0542725 80.6073874,100.780959 C78.8664268,103.504266 83.783877,105.122708 83.783877,105.122708 C83.783877,105.122708 81.4252656,107.051999 84.258993,109.227942 C87.1470192,111.437673 89.5395675,109.673943 89.5395675,109.673943 C89.5395675,109.673943 89.4173948,111.887052 92.349539,112.167492 C94.4027187,112.366841 94.5656156,109.967898 94.5656156,109.967898 C94.5656156,109.967898 98.6651876,112.400629 99.9480006,109.427291 C100.959319,107.082408 95.2477463,105.139602 95.2477463,105.139602 C95.2477463,105.139602 96.6120079,103.159629 94.8710473,101.794597 C93.1300866,100.422807 90.8834669,101.821627 90.8834669,101.821627 C90.8834669,101.821627 89.5429611,96.9730586 85.8675998,100.767444 Z" id="Stroke-120" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-74)" />
          <path d="M95.1041934,76.9739808 C95.1041934,76.9739808 91.581548,74.2608097 89.843981,76.9874959 C88.1030204,79.7108034 93.0204706,81.3292454 93.0204706,81.3292454 C93.0204706,81.3292454 90.6618592,83.2551576 93.4955866,85.4311005 C96.3836128,87.6442102 98.7761611,85.88048 98.7761611,85.88048 C98.7761611,85.88048 98.6539884,88.0935897 101.586133,88.3740295 C103.639312,88.5733783 103.802209,86.1744351 103.802209,86.1744351 C103.802209,86.1744351 107.901781,88.6071663 109.184594,85.6338281 C110.195913,83.2889455 104.48434,81.3461394 104.48434,81.3461394 C104.48434,81.3461394 105.848601,79.3661665 104.107641,77.9977551 C102.36668,76.6293438 100.12006,78.0281643 100.12006,78.0281643 C100.12006,78.0281643 98.7795548,73.1795958 95.1041934,76.9739808 Z" id="Stroke-123" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-76)" />
          <path d="M104.340787,53.180518 C104.340787,53.180518 100.818142,50.4673469 99.0805746,53.1906544 C97.339614,55.9173406 102.257064,57.5357826 102.257064,57.5357826 C102.257064,57.5357826 99.8984528,59.4616948 102.73218,61.6376377 C105.616813,63.8507474 108.012755,62.0870173 108.012755,62.0870173 C108.012755,62.0870173 107.890582,64.3001269 110.822726,64.5805667 C112.875906,64.7765368 113.038803,62.3809723 113.038803,62.3809723 C113.038803,62.3809723 117.138375,64.8137035 118.421188,61.8403653 C119.432506,59.4954827 113.720934,57.5526766 113.720934,57.5526766 C113.720934,57.5526766 115.085195,55.5727037 113.344234,54.2042924 C111.603274,52.8325023 109.35326,54.2347015 109.35326,54.2347015 C109.35326,54.2347015 108.012755,49.386133 104.340787,53.180518 Z" id="Stroke-126" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-78)" />
          <path d="M113.575684,29.3870552 C113.575684,29.3870552 110.053038,26.6738841 108.315471,29.3971916 C106.574511,32.1238778 111.491961,33.7423198 111.491961,33.7423198 C111.491961,33.7423198 109.13335,35.668232 111.967077,37.8441749 C114.855103,40.0572846 117.247651,38.2935545 117.247651,38.2935545 C117.247651,38.2935545 117.125479,40.5066641 120.057623,40.787104 C122.110803,40.983074 122.2737,38.5875095 122.2737,38.5875095 C122.2737,38.5875095 126.373272,41.0202407 127.656085,38.0469025 C128.667403,35.70202 122.95583,33.7592138 122.95583,33.7592138 C122.95583,33.7592138 124.320092,31.7792409 122.579131,30.4108296 C120.838171,29.0390395 118.591551,30.4412387 118.591551,30.4412387 C118.591551,30.4412387 117.251045,25.5926702 113.575684,29.3870552 Z" id="Stroke-129" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-80)" />
          <path d="M122.811938,5.59055148 C122.811938,5.59055148 119.289293,2.87738042 117.551726,5.60406665 C115.810765,8.33075289 120.728215,9.94919491 120.728215,9.94919491 C120.728215,9.94919491 118.369604,11.8751071 121.203331,14.05105 C124.087964,16.2641597 126.483906,14.4970508 126.483906,14.4970508 C126.483906,14.4970508 126.361733,16.7135392 129.293877,16.993979 C131.347057,17.1899491 131.509954,14.7943846 131.509954,14.7943846 C131.509954,14.7943846 135.609526,17.2271158 136.892339,14.2503988 C137.903657,11.9055163 132.192085,9.96271008 132.192085,9.96271008 C132.192085,9.96271008 133.556346,7.98611597 131.815385,6.61770466 C130.074425,5.24591456 127.827805,6.64473501 127.827805,6.64473501 C127.827805,6.64473501 126.483906,1.79954534 122.811938,5.59055148 Z" id="Stroke-132" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-82)" />
          <path d="M16.1826815,197.020143 C16.1826815,197.020143 12.6600361,194.306972 10.9224692,197.033659 C9.18150853,199.756966 14.0989587,201.375408 14.0989587,201.375408 C14.0989587,201.375408 11.7403473,203.30132 14.5740747,205.477263 C17.462101,207.690373 19.8546492,205.926643 19.8546492,205.926643 C19.8546492,205.926643 19.7324765,208.139752 22.6646207,208.420192 C24.7178004,208.619541 24.8806973,206.220598 24.8806973,206.220598 C24.8806973,206.220598 28.9802693,208.653329 30.2630824,205.679991 C31.2744006,203.335108 25.562828,201.392302 25.562828,201.392302 C25.562828,201.392302 26.9270896,199.412329 25.186129,198.043918 C23.4451683,196.675507 21.1985486,198.074327 21.1985486,198.074327 C21.1985486,198.074327 19.8580429,193.225758 16.1826815,197.020143 Z" id="Stroke-135" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-84)" />
          <path d="M25.4192752,173.226681 C25.4192752,173.226681 21.8966297,170.51351 20.1590628,173.236817 C18.4181021,175.963503 23.3355523,177.581945 23.3355523,177.581945 C23.3355523,177.581945 20.9769409,179.507857 23.8106683,181.6838 C26.6953009,183.89691 29.0912428,182.13318 29.0912428,182.13318 C29.0912428,182.13318 28.9690701,184.34629 31.9012143,184.626729 C33.954394,184.822699 34.1172909,182.427135 34.1172909,182.427135 C34.1172909,182.427135 38.2168629,184.859866 39.499676,181.886528 C40.5109942,179.541645 34.7994217,177.598839 34.7994217,177.598839 C34.7994217,177.598839 36.1636832,175.618866 34.4227226,174.250455 C32.681762,172.878665 30.4351422,174.280864 30.4351422,174.280864 C30.4351422,174.280864 29.0946365,169.432296 25.4192752,173.226681 Z" id="Stroke-138" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-86)" />
          <path d="M34.6541719,149.433218 C34.6541719,149.433218 31.1315265,146.720047 29.3939595,149.443354 C27.6529989,152.17004 32.5704491,153.788482 32.5704491,153.788482 C32.5704491,153.788482 30.2118377,155.714395 33.045565,157.890338 C35.9335913,160.103447 38.3261395,158.339717 38.3261395,158.339717 C38.3261395,158.339717 38.2039669,160.552827 41.1361111,160.833267 C43.1892907,161.029237 43.3521876,158.633672 43.3521876,158.633672 C43.3521876,158.633672 47.4517596,161.066403 48.7345727,158.093065 C49.745891,155.748183 44.0343184,153.805376 44.0343184,153.805376 C44.0343184,153.805376 45.39858,151.825404 43.6576193,150.456992 C41.9166587,149.085202 39.670039,150.487401 39.670039,150.487401 C39.670039,150.487401 38.3295332,145.638833 34.6541719,149.433218 Z" id="Stroke-141" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-88)" />
          <path d="M43.8907655,125.636714 C43.8907655,125.636714 40.3681201,122.923543 38.6305531,125.650229 C36.8895925,128.376916 41.8070427,129.995358 41.8070427,129.995358 C41.8070427,129.995358 39.4484313,131.92127 42.2821586,134.097213 C45.1667912,136.310322 47.5627331,134.546592 47.5627331,134.546592 C47.5627331,134.546592 47.4405605,136.759702 50.3727047,137.040142 C52.4258844,137.236112 52.5887813,134.840547 52.5887813,134.840547 C52.5887813,134.840547 56.6883532,137.273278 57.9711663,134.296562 C58.9824846,131.951679 53.270912,130.012252 53.270912,130.012252 C53.270912,130.012252 54.6351736,128.032279 52.8942129,126.663867 C51.1532523,125.292077 48.9066326,126.690898 48.9066326,126.690898 C48.9066326,126.690898 47.5661268,121.845708 43.8907655,125.636714 Z" id="Stroke-144" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-90)" />
          <path d="M53.1273591,101.843251 C53.1273591,101.843251 49.6047137,99.1300803 47.8671467,101.856767 C46.1261861,104.580074 51.0436363,106.201895 51.0436363,106.201895 C51.0436363,106.201895 48.6816312,108.127807 51.5187522,110.30375 C54.4033848,112.513481 56.7993268,110.749751 56.7993268,110.749751 C56.7993268,110.749751 56.6771541,112.96286 59.6059046,113.2433 C61.662478,113.442649 61.8253749,111.043706 61.8253749,111.043706 C61.8253749,111.043706 65.9249468,113.479816 67.2077599,110.503099 C68.2190782,108.158216 62.5075056,106.21541 62.5075056,106.21541 C62.5075056,106.21541 63.8717672,104.235437 62.1308065,102.870405 C60.3898459,101.498614 58.1398325,102.897435 58.1398325,102.897435 C58.1398325,102.897435 56.7993268,98.0488664 53.1273591,101.843251 Z" id="Stroke-147" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-92)" />
          <path d="M62.3619165,78.0497886 C62.3619165,78.0497886 58.8392711,75.3366175 57.1017041,78.0633037 C55.3607435,80.7866112 60.2781937,82.4050532 60.2781937,82.4050532 C60.2781937,82.4050532 57.9195823,84.3343442 60.7533096,86.5069083 C63.6413359,88.720018 66.0338841,86.9562878 66.0338841,86.9562878 C66.0338841,86.9562878 65.9117115,89.1693975 68.8438557,89.4498373 C70.8970354,89.6491861 71.0599323,87.2502429 71.0599323,87.2502429 C71.0599323,87.2502429 75.1595042,89.6829741 76.4423173,86.7096359 C77.4536356,84.3647533 71.742063,82.4219472 71.742063,82.4219472 C71.742063,82.4219472 73.1063246,80.4419743 71.3653639,79.073563 C69.6244033,77.7051516 67.3777836,79.1039721 67.3777836,79.1039721 C67.3777836,79.1039721 66.0372778,74.2554036 62.3619165,78.0497886 Z" id="Stroke-150" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-94)" />
          <path d="M71.5985101,54.2563258 C71.5985101,54.2563258 68.0758647,51.5431547 66.3382977,54.269841 C64.5973371,56.9931484 69.5147873,58.6115904 69.5147873,58.6115904 C69.5147873,58.6115904 67.1561759,60.5375026 69.9899032,62.7134455 C72.8745358,64.9265552 75.2704777,63.1628251 75.2704777,63.1628251 C75.2704777,63.1628251 75.1483051,65.3759347 78.0804493,65.6563745 C80.133629,65.8557234 80.2965259,63.4567801 80.2965259,63.4567801 C80.2965259,63.4567801 84.3960978,65.8895113 85.6789109,62.9161731 C86.6902292,60.5712906 80.9786566,58.6284844 80.9786566,58.6284844 C80.9786566,58.6284844 82.3429182,56.6485115 80.6019575,55.2801002 C78.8609969,53.9116889 76.6143772,55.3105093 76.6143772,55.3105093 C76.6143772,55.3105093 75.2704777,50.4619408 71.5985101,54.2563258 Z" id="Stroke-153" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-96)" />
          <path d="M80.8334069,30.462863 C80.8334069,30.462863 77.3107614,27.7496919 75.5731945,30.4729994 C73.8322339,33.1996856 78.749684,34.8181276 78.749684,34.8181276 C78.749684,34.8181276 76.3910727,36.7440398 79.2248,38.9199827 C82.1128263,41.1330924 84.5053745,39.3693623 84.5053745,39.3693623 C84.5053745,39.3693623 84.3832018,41.5824719 87.315346,41.8629118 C89.3685257,42.0588818 89.5314226,39.6633173 89.5314226,39.6633173 C89.5314226,39.6633173 93.6309946,42.0960485 94.9138077,39.1227104 C95.9251259,36.7778278 90.2135534,34.8350216 90.2135534,34.8350216 C90.2135534,34.8350216 91.5778149,32.8550487 89.8368543,31.4866374 C88.0958937,30.1148473 85.8492739,31.5170465 85.8492739,31.5170465 C85.8492739,31.5170465 84.5087682,26.6684781 80.8334069,30.462863 Z" id="Stroke-156" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-98)" />
          <path d="M90.0700005,6.6694002 C90.0700005,6.6694002 86.547355,3.95622914 84.8097881,6.67953658 C83.0688275,9.40622281 87.9862776,11.0246648 87.9862776,11.0246648 C87.9862776,11.0246648 85.6276663,12.950577 88.4613936,15.12652 C91.3460262,17.3396296 93.7419681,15.5758995 93.7419681,15.5758995 C93.7419681,15.5758995 93.6197954,17.7890091 96.5519396,18.069449 C98.6051193,18.265419 98.7680162,15.8698545 98.7680162,15.8698545 C98.7680162,15.8698545 102.867588,18.3025857 104.150401,15.3258688 C105.16172,12.984365 99.450147,11.0415588 99.450147,11.0415588 C99.450147,11.0415588 100.814409,9.06158589 99.0734479,7.69317458 C97.3324873,6.32138448 95.0858675,7.72358372 95.0858675,7.72358372 C95.0858675,7.72358372 93.7453618,2.87501526 90.0700005,6.6694002 Z" id="Stroke-159" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-100)" />
          <path d="M99.3048973,-17.1274414 C99.3048973,-17.1274414 95.7822518,-19.8406124 94.0446849,-17.1139262 C92.3037242,-14.38724 97.2211744,-12.768798 97.2211744,-12.768798 C97.2211744,-12.768798 94.862563,-10.8428857 97.6962904,-8.66694283 C100.584317,-6.45721198 102.976865,-8.22094211 102.976865,-8.22094211 C102.976865,-8.22094211 102.854692,-6.00445367 105.786836,-5.72739261 C107.840016,-5.5280438 108.002913,-7.92360829 108.002913,-7.92360829 C108.002913,-7.92360829 112.102485,-5.49087708 113.385298,-8.46759402 C114.396616,-10.8124766 108.685044,-12.7552828 108.685044,-12.7552828 C108.685044,-12.7552828 110.049305,-14.7352557 108.308345,-16.1002882 C106.567384,-17.4720783 104.320764,-16.0732579 104.320764,-16.0732579 C104.320764,-16.0732579 102.980259,-20.9218263 99.3048973,-17.1274414 Z" id="Stroke-162" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-102)" />
          <path d="M-17.4260033,197.762464 C-17.4260033,197.762464 -20.9486487,195.049293 -22.6862157,197.775979 C-24.4271763,200.499287 -19.5097261,202.117729 -19.5097261,202.117729 C-19.5097261,202.117729 -21.8683375,204.043641 -19.0346101,206.219584 C-16.1499775,208.432694 -13.7540356,206.668964 -13.7540356,206.668964 C-13.7540356,206.668964 -13.8762083,208.882073 -10.9440641,209.162513 C-8.89088443,209.361862 -8.72798753,206.962919 -8.72798753,206.962919 C-8.72798753,206.962919 -4.62841555,209.39565 -3.34560246,206.422312 C-2.3342842,204.077429 -8.04585676,202.134623 -8.04585676,202.134623 C-8.04585676,202.134623 -6.68159522,200.15465 -8.42255584,198.786239 C-10.1635165,197.417827 -12.4101362,198.816648 -12.4101362,198.816648 C-12.4101362,198.816648 -13.750642,193.968079 -17.4260033,197.762464 Z" id="Stroke-165" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-104)" />
          <path d="M-8.1911065,173.969339 C-8.1911065,173.969339 -11.713752,171.256168 -13.4513189,173.979476 C-15.1922795,176.706162 -10.2748293,178.324604 -10.2748293,178.324604 C-10.2748293,178.324604 -12.6334407,180.250516 -9.79971339,182.426459 C-6.9116871,184.639569 -4.51913887,182.875839 -4.51913887,182.875839 C-4.51913887,182.875839 -4.64131155,185.088948 -1.70916735,185.369388 C0.344012331,185.565358 0.506909231,183.169794 0.506909231,183.169794 C0.506909231,183.169794 4.60648122,185.602525 5.88929431,182.629187 C6.90061256,180.284304 1.18904,178.341498 1.18904,178.341498 C1.18904,178.341498 2.55330154,176.361525 0.812340918,174.993114 C-0.928619701,173.621324 -3.17523945,175.023523 -3.17523945,175.023523 C-3.17523945,175.023523 -4.51574519,170.174954 -8.1911065,173.969339 Z" id="Stroke-168" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-106)" />
          <path d="M1.04548711,150.175877 C1.04548711,150.175877 -2.47715836,147.462706 -4.21472529,150.186013 C-5.95568591,152.912699 -1.03823574,154.531141 -1.03823574,154.531141 C-1.03823574,154.531141 -3.39684711,156.457053 -0.563119782,158.632996 C2.32151282,160.846106 4.71745473,159.082376 4.71745473,159.082376 C4.71745473,159.082376 4.59528206,161.295486 7.52742626,161.575925 C9.58060594,161.771895 9.74350284,159.376331 9.74350284,159.376331 C9.74350284,159.376331 13.8430748,161.809062 15.1258879,158.835724 C16.1372062,156.490841 10.4256336,154.548035 10.4256336,154.548035 C10.4256336,154.548035 11.7898951,152.568062 10.0489345,151.199651 C8.3079739,149.827861 6.06135416,151.23006 6.06135416,151.23006 C6.06135416,151.23006 4.72084842,146.381492 1.04548711,150.175877 Z" id="Stroke-171" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-108)" />
          <path d="M10.2803839,126.379035 C10.2803839,126.379035 6.7577384,123.665864 5.02017147,126.39255 C3.27921085,129.119236 8.19666102,130.737678 8.19666102,130.737678 C8.19666102,130.737678 5.83804966,132.663591 8.67177698,134.839534 C11.5598033,137.052643 13.9523515,135.285534 13.9523515,135.285534 C13.9523515,135.285534 13.8301788,137.502023 16.762323,137.782463 C18.8155027,137.978433 18.9783996,135.582868 18.9783996,135.582868 C18.9783996,135.582868 23.0779716,138.015599 24.3607847,135.038882 C25.3721029,132.694 19.6605304,130.751194 19.6605304,130.751194 C19.6605304,130.751194 21.0247919,128.7746 19.2838313,127.406188 C17.5428707,126.034398 15.2962509,127.433219 15.2962509,127.433219 C15.2962509,127.433219 13.9557452,122.58465 10.2803839,126.379035 Z" id="Stroke-174" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-110)" />
          <path d="M19.5166381,102.585572 C19.5166381,102.585572 15.9939926,99.8724012 14.2564257,102.599087 C12.5154651,105.322395 17.4329153,106.940837 17.4329153,106.940837 C17.4329153,106.940837 15.0743039,108.870128 17.9080312,111.046071 C20.7960575,113.255802 23.1886057,111.492072 23.1886057,111.492072 C23.1886057,111.492072 23.0664331,113.705181 25.9985773,113.985621 C28.0517569,114.18497 28.2146538,111.786027 28.2146538,111.786027 C28.2146538,111.786027 32.3142258,114.218758 33.5970389,111.24542 C34.6083572,108.900537 28.8967846,106.957731 28.8967846,106.957731 C28.8967846,106.957731 30.2610461,104.977758 28.5200855,103.612725 C26.7791249,102.240935 24.5325052,103.639756 24.5325052,103.639756 C24.5325052,103.639756 23.1919994,98.7911873 19.5166381,102.585572 Z" id="Stroke-177" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-112)" />
          <path d="M28.7532317,78.7921095 C28.7532317,78.7921095 25.2305862,76.0789384 23.4930193,78.8056246 C21.7520587,81.5289321 26.6695089,83.1473741 26.6695089,83.1473741 C26.6695089,83.1473741 24.3108975,85.0732863 27.1446248,87.2492292 C30.0292574,89.4623389 32.4251993,87.6986087 32.4251993,87.6986087 C32.4251993,87.6986087 32.3030267,89.9117184 35.2351709,90.1921582 C37.2883505,90.391507 37.4512474,87.9925638 37.4512474,87.9925638 C37.4512474,87.9925638 41.5508194,90.425295 42.8336325,87.4519568 C43.8449508,85.1070742 38.1333782,83.1642681 38.1333782,83.1642681 C38.1333782,83.1642681 39.4976397,81.1842952 37.7566791,79.8158839 C36.0157185,78.4474725 33.7657051,79.846293 33.7657051,79.846293 C33.7657051,79.846293 32.4251993,74.9977245 28.7532317,78.7921095 Z" id="Stroke-180" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-114)" />
          <path d="M37.9881285,54.9986467 C37.9881285,54.9986467 34.465483,52.2854756 32.7279161,55.0087831 C30.9869555,57.7354693 35.9044056,59.3539113 35.9044056,59.3539113 C35.9044056,59.3539113 33.5457943,61.2798235 36.3795216,63.4557664 C39.2675479,65.6688761 41.6600961,63.905146 41.6600961,63.905146 C41.6600961,63.905146 41.5379234,66.1182556 44.4700676,66.3986954 C46.5232473,66.5946655 46.6861442,64.199101 46.6861442,64.199101 C46.6861442,64.199101 50.7857162,66.6318322 52.0685293,63.658494 C53.0798475,61.3136115 47.368275,59.3708053 47.368275,59.3708053 C47.368275,59.3708053 48.7325365,57.3908324 46.9915759,56.0224211 C45.2506153,54.650631 43.0039955,56.0528302 43.0039955,56.0528302 C43.0039955,56.0528302 41.6634898,51.2042617 37.9881285,54.9986467 Z" id="Stroke-183" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-116)" />
          <path d="M47.2247221,31.2051839 C47.2247221,31.2051839 43.7020766,28.4920128 41.9645097,31.2153203 C40.2235491,33.9420065 45.1409992,35.5604485 45.1409992,35.5604485 C45.1409992,35.5604485 42.7823879,37.4863607 45.6161152,39.6623036 C48.5007478,41.8754133 50.8966897,40.1116832 50.8966897,40.1116832 C50.8966897,40.1116832 50.774517,42.3247928 53.7066612,42.6052327 C55.7598409,42.8012027 55.9227378,40.4056382 55.9227378,40.4056382 C55.9227378,40.4056382 60.0223098,42.8383694 61.3051229,39.8650312 C62.3164411,37.5201487 56.6048686,35.5773425 56.6048686,35.5773425 C56.6048686,35.5773425 57.9691301,33.5973696 56.2281695,32.2289583 C54.4872089,30.8571682 52.2405891,32.2593674 52.2405891,32.2593674 C52.2405891,32.2593674 50.9000834,27.410799 47.2247221,31.2051839 Z" id="Stroke-186" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-118)" />
          <path d="M56.4596188,7.40868018 C56.4596188,7.40868018 52.9369734,4.69888792 51.1994064,7.42219536 C49.4584458,10.1488816 54.375896,11.7673236 54.375896,11.7673236 C54.375896,11.7673236 52.0172846,13.6932358 54.851012,15.8691787 C57.7390382,18.0822884 60.1315865,16.3185583 60.1315865,16.3185583 C60.1315865,16.3185583 60.0094138,18.5316679 62.941558,18.8121077 C64.9947377,19.0080778 65.1576346,16.6125133 65.1576346,16.6125133 C65.1576346,16.6125133 69.2572066,19.0452445 70.5400196,16.0685275 C71.5513379,13.7270238 65.8397653,11.7842176 65.8397653,11.7842176 C65.8397653,11.7842176 67.2040269,9.80424467 65.4630663,8.43583336 C63.7221056,7.06404326 61.4754859,8.46286371 61.4754859,8.46286371 C61.4754859,8.46286371 60.1349801,3.61767404 56.4596188,7.40868018 Z" id="Stroke-189" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-120)" />
          <path d="M65.6962124,-16.3847826 C65.6962124,-16.3847826 62.173567,-19.0979537 60.436,-16.3712674 C58.6950394,-13.64796 63.6124896,-12.0261392 63.6124896,-12.0261392 C63.6124896,-12.0261392 61.2538782,-10.100227 64.0876056,-7.92428405 C66.9722382,-5.7145532 69.3681801,-7.47828333 69.3681801,-7.47828333 C69.3681801,-7.47828333 69.2460074,-5.26517368 72.1781516,-4.98473383 C74.2313313,-4.78538503 74.3942282,-7.18432831 74.3942282,-7.18432831 C74.3942282,-7.18432831 78.4938002,-4.7482183 79.7766132,-7.72493524 C80.7879315,-10.0698178 75.0763589,-12.012624 75.0763589,-12.012624 C75.0763589,-12.012624 76.4406205,-13.9925969 74.6996599,-15.3576294 C72.9586992,-16.7294195 70.7120795,-15.3305991 70.7120795,-15.3305991 C70.7120795,-15.3305991 69.3715738,-20.1791675 65.6962124,-16.3847826 Z" id="Stroke-192" stroke="#FEFEFE" strokeWidth="0.25" mask="url(#mask-122)" />
        </g>
        <g transform="translate(107.292631, 0.841170)">
          <path d="M34.0598924,265.159496 C36.6756991,263.546833 35.7856454,265.146001 43.9965608,260.989513 C47.4005067,259.265515 49.5950666,261.765481 53.8041374,261.650773 C61.009496,261.448346 62.6774974,265.955707 64.4134419,268.340964 C66.1459892,270.726222 67.0768087,269.450935 67.0088657,275.891468 C66.8763768,288.678066 56.7358793,293.445207 65.2729212,297.021406 C73.8133603,300.597606 84.7623799,292.635502 85.5641076,297.800748 C86.3624383,302.965994 77.8661621,310.199363 82.6425573,314.93614 C87.4189524,319.676291 93.0208554,320.071022 89.8207386,328.815841 C86.6172246,337.56066 47.1321317,344.314953 42.0635816,335.573508 C36.9950314,326.828689 53.0024097,315.303881 44.1969928,310.931472 C35.394973,306.559062 26.3483583,306.559062 27.3912838,296.026144 C28.0571256,289.302214 22.4552226,272.315269 34.0598924,265.159496" id="Fill-196" fill="#F06140" />
          <path d="M145.845883,548.424494 C141.579061,534.119698 134.105327,522.459939 123.968227,516.63006 C122.069219,515.540331 120.319686,514.808223 118.716231,514.373006 C107.651708,508.371064 87.7138252,496.971086 71.866113,484.693927 C68.102069,481.778987 66.0909553,467.811568 65.0412355,455.598511 C65.0140583,455.601884 41.9134275,458.014132 32.8905928,459.950677 C27.2105554,461.175357 9.34493518,466.718466 2.67292947,468.810205 L0.712772999,469.427605 C5.3091191,481.64741 12.0252878,495.608082 21.2553487,507.13289 C52.4208173,546.056106 102.528803,541.528503 113.603517,539.983315 C114.43582,541.06967 115.261327,542.024448 116.032481,542.729566 C120.832656,547.142461 120.76811,559.024888 126.638388,563.795403 C127.059635,564.136154 127.175138,565.924254 127.739065,566.065952 C131.041097,566.909395 130.779516,562.536986 131.458946,562.661816 C132.376177,562.833878 132.216511,564.952607 133.242451,565.023456 C135.399642,565.171902 135.657826,562.678684 136.493525,562.489753 C137.152572,562.344681 137.414153,564.318338 138.076597,564.13278 C140.947191,563.33657 140.471589,560.482358 141.24614,560.053889 C141.786287,559.756997 142.564235,561.885848 144.381711,560.782624 C145.737174,559.95605 147.772068,554.892017 145.845883,548.424494" id="Fill-198" fill="#F496A4" />
        </g>
        <g transform="translate(0.587942, 9.547508)">
            <path d="M52.3046963,206.189377 L144.34915,206.189377 C147.401044,205.369833 143.271609,195.97895 128.561137,195.467582 C122.849491,195.267776 122.39597,177.471493 109.707591,183.255708 C97.0192118,189.036537 93.2103112,194.035074 92.231659,191.068463 C89.9231311,184.071865 82.2985099,183.181204 78.5475783,189.564838 C74.8000565,195.951858 65.988777,196.480158 61.8252429,198.07522 C57.6617087,199.670282 52.3046963,206.189377 52.3046963,206.189377" id="Fill-280" fill="#FEFEFE" />
            <path d="M153.755122,566.948987 C153.755122,566.948987 152.326358,568.036067 151.787588,573.623863 C151.255638,579.204885 154.733775,582.930082 154.733775,582.930082" id="Stroke-286" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M80.0203304,501.013674 C80.0203304,501.013674 88.4155975,492.767442 101.44497,495.161727 C111.753212,497.058192 115.367747,501.013674 115.367747,501.013674" id="Stroke-288" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M154.734116,481.980964 C154.734116,481.980964 161.564221,475.482188 174.416277,479.671342 C181.055426,481.835343 184.458544,486.671326 184.458544,486.671326" id="Stroke-290" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M219.796071,507.882937 C219.796071,507.882937 214.050326,507.882937 213.518376,515.245281 C213.000065,522.377341 216.764637,526.187202 216.764637,526.187202" id="Stroke-292" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M228.512555,528.40708 C228.512555,528.40708 234.848219,523.621895 239.847188,529.33838 C242.322803,532.16953 243.079809,535.522207 243.216207,538.18403" id="Stroke-294" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M88.222254,368.568351 C88.222254,368.568351 111.822434,370.6951 116.807763,386.12419" id="Stroke-296" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M143.420624,378.807225 C143.420624,378.807225 155.805518,370.161381 166.212648,378.292471" id="Stroke-298" stroke="#FEFEFE" strokeWidth="0.25" />
            <polygon fill="#00A1D6" points="0 433.65669 10.0354472 435.312709 8.97836646 419.931032" />
            <path d="M114.029687,427.875861 C108.328271,440.111441 108.328271,463.610662 108.328271,463.610662" id="Stroke-336" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M290.409065,348.354754 L388.4175,348.354754 C388.4175,348.354754 375.725711,340.907746 369.833339,335.587488 C363.937556,330.270615 350.01478,324.659114 344.122407,332.515893 C338.230034,340.379446 336.109053,331.865677 325.923568,335.587488 C315.738084,339.312685 304.355711,344.09787 300.407003,344.09787 C296.454885,344.09787 290.409065,348.354754 290.409065,348.354754" id="Fill-278" fill="#FEFEFE" />
            <path d="M144.291181,552.78748 C144.291181,552.78748 136.346025,552.2287 136.346025,560.390269 C136.346025,568.548451 140.318603,576.550852 140.318603,576.550852" id="Stroke-284" stroke="#FEFEFE" strokeWidth="0.25" />
            <path d="M6.10890373,429.354087 C6.10890373,429.354087 1.28725155,463.172105 20.5704503,494.727911" id="Stroke-312" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
            <path d="M2.09131491,467.505525 C2.09131491,467.505525 5.57286149,487.188112 17.0882217,501.015367" id="Stroke-314" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
            <path d="M180.486989,380.546892 C180.486989,380.546892 192.609319,403.304461 188.046822,416.600028" id="Stroke-316" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
            <path d="M189.074918,386.122835 C189.074918,386.122835 193.88634,395.429055 193.190713,405.002812" id="Stroke-318" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round" />
          </g>
      </g>
    </FigureRed>
  </>
);

export default About;
