import React from "react";
import tw from "twin.macro";

import ArrowRed from "@components/arrows/red";

const ArrowWrapper = tw.div`
  absolute! hidden bottom-10 -left-40 w-200
  md:block
  lg:bottom-20 lg:-left-80 lg:w-260
  xl:-bottom-10 xl:-left-190 xl:w-340
`;

const DetailsMarta = () => (
  <ArrowWrapper>
    <ArrowRed />
  </ArrowWrapper>
);

export default DetailsMarta;
