import React from "react";
import { people } from "@utils/variables";

import PersonInfo from "@components/person/info";

const MoreMarta = ({ width, scroll }: { width: number, scroll: any }) => (
  <>
    <PersonInfo
      width={ width }
      scroll={ scroll }
      headings={'Education'}
      person={people[1]}
      flexStart
    />
    <PersonInfo
      width={ width }
      scroll={ scroll }
      headings={'Customers'}
      person={people[1]}
      xlWider
    />
  </>
);

export default MoreMarta;
