import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowSecondary from "@components/arrows/secondary";

const Wrapper = tw.div`relative`;

const Person = tw.div`
  relative max-w-300 mx-auto mb-30
  md:mb-20
  lg:min-w-475 lg:mb-0 lg:overflow-hidden
`;

const Motion = tw(motion.div)`relative`;

const Background = tw.div`
  absolute inset-0 right-20 bg-yellow
  lg:right-35
`;

const ArrowWrapper = styled.div`
  svg {
    transform: skew(10deg, -10deg) rotate(30deg);
    ${tw`
      hidden
      md:block md:top-50 md:left-380 md:w-120
      lg:top-40 lg:left-460 lg:w-180
      xl:left-550
    `}
  }
`;

const ImageMarta = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      person: file(relativePath: { eq: "about/marta.png" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 250, sectionStart + 630], [-500, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Person>
        <Background />
        <Motion style={ width >= 1024 && { left } }>
          <Img fluid={data.person.childImageSharp.fluid} />
        </Motion>
      </Person>
      <ArrowWrapper>
        <ArrowSecondary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default ImageMarta;
