import React, { ReactNode } from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

import PersonDetails from "@components/person/details";
import PersonPublications from "@components/person/publications";

type PersonProps = {
  moreInfo: ReactNode,
  details: ReactNode,
  image: ReactNode,
  name: 'Beata' | 'Marta',
  isFirstPerson?: boolean,
  scroll: any,
  width: number
};

const Wrapper = tw.div`relative flex flex-wrap`;

const Column = tw.div`w-full sm:w-1/2 sm:mb-10 lg:mb-25`;

const ColumnFullWidth = styled.div<{ marginSmall: boolean }>`
  ${({ marginSmall }) => marginSmall ? tw`mt-20` : tw`mt-50`}
  ${tw`w-full md:flex lg:justify-between lg:mt-80`}
`;

const ColumnLarge = tw(Column)`lg:w-7/12`;

const ColumnSmall = tw(Column)`lg:w-5/12`;

const Note = tw(motion.div)`
  -tracking-03
  md:pr-60
  lg:pr-90 lg:text-xl lg:leading-40 lg:-tracking-024 lg:opacity-0
  xl:pr-40 xl:text-2xl xl:leading-50
`;

const Description = tw.div`
  relative flex mt-20 text-sm text-second leading-20
  sm:pl-30
  md:pl-20 md:pr-10 md:-tracking-027
  lg:mt-70 lg:text-md lg:leading-30
  xl:pl-64 xl:pr-70 xl:whitespace-pre-line
`;

const DescriptionCharacter = tw.span`
  font-pangramBold mr-15 text-sm text-portfolioText leading-32
  lg:min-w-40 lg:mr-10 lg:text-lg lg:-tracking-03
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const Person = ({ moreInfo, details, image, name, isFirstPerson, scroll, width }: PersonProps) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 80, sectionStart + 400], [0, 1]);
  const top = useTransform(scroll, [sectionStart + 200, sectionStart + 480], [0, 360]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper>
      <ColumnLarge>
        <PersonDetails person={name} width={ width } scroll={ scroll } />
      </ColumnLarge>
      <ColumnSmall>
        { image }
      </ColumnSmall>
      <ColumnLarge ref={Ref}>
        <Note style={ width >= 1024 && { opacity } }>
          {t(`About:Persons:${name}:Note1`)}
          <br/>
          {t(`About:Persons:${name}:Note2`)}
          <br/>
          {t(`About:Persons:${name}:Note3`)}
          <br/>
          {t(`About:Persons:${name}:Note4`)}
        </Note>
      </ColumnLarge>
      <ColumnSmall>
        <Description>
          <DescriptionCharacter>
            {t('Description Character')}
          </DescriptionCharacter>
          {t(`About:Persons:${name}:Description`)}
          <Mask style={ width >= 1024 && { top } } />
        </Description>
      </ColumnSmall>
      <ColumnFullWidth marginSmall={isFirstPerson}>
        { moreInfo }
      </ColumnFullWidth>
      <PersonPublications
        person={name}
        width={ width }
        scroll={ scroll }
        background={!isFirstPerson}
      />
      { details }
    </Wrapper>
  );
};

export default Person;
