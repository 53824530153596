import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { PeopleTypes } from "@utils/types";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

type PersonPublicationsProps = {
  person: PeopleTypes,
  background: boolean,
  width: number,
  scroll: any
};

const people = {
  Beata: [
    { link: 'https://www.slideshare.net/Dajemyslowo/twitter-i-periscope-odkryj-nowe-moliwoci-promocji-twojej-restauracji' },
    { link: 'https://www.slideshare.net/Dajemyslowo/w-kuchni-copywritera-czyli-jak-tworzy-angaujce-treci-w-biznesie-restauracyjnym' },
    { link: 'https://www.slideshare.net/Dajemyslowo/copywriting-w-content-marketingu-jak-tworzy-angaujce-treci' },
    { link: 'https://www.slideshare.net/Dajemyslowo/copywriting-w-email-marketingu-jak-tworzy-skuteczne-tematy-i-zachcajce-teksty' },
    { link: '' }
  ],
  Marta: [
    { link: 'https://www.slideshare.net/Dajemyslowo/webwriting-w-praktyce-jak-pisa-eby-treci-na-www-przycigay-odbiorcw' },
    { link: 'https://www.slideshare.net/Dajemyslowo/jakich-sw-uywa-by-sprzedawa-wicej-w-social-mediach' },
    { link: '' },
    { link: '' }
  ]
}

const Wrapper = styled.div<{ background: boolean }>`
  ${tw`
    flex flex-wrap w-screen mt-50 -mx-20 mb-70 py-35 px-20
    md:mx-0 md:p-50
    lg:mt-80 lg:mb-75 lg:px-80
    xl:-mx-80
  `}

  ${({ background }) => background ? tw`bg-lightBlue` : tw`bg-white`}
`;

const Heading = tw(motion.div)`
  mb-20 text-xl leading-25 -tracking-016
  md:ml-20
  lg:relative lg:ml-0 lg:text-2xl lg:leading-50 lg:-tracking-024
`;

const PublicationImage = tw(Img)`
  w-200 mx-auto mb-40 z-10
  md:w-220 md:mx-0
  lg:w-285 lg:mb-0
`;

const PublicationWrapper = tw.div`
  min-h-320
  md:min-h-355
  lg:absolute lg:-bottom-100 lg:min-h-410
  xl:-bottom-120
`;

const Column = tw.div`
  w-full justify-between
  md:flex
  lg:relative lg:flex-col lg:w-1/3 lg:h-full
`;

const List = tw.ul`
  w-full
  md:-mt-220
  lg:w-2/3 lg:mt-12 lg:pl-40
  xl:pl-80
`;

const ListItem = styled(motion.li)<{ mdMaxWidth: boolean }>`
  ${({ mdMaxWidth }) => mdMaxWidth ? tw`md:max-w-400` : ''}
  ${tw`
    flex mb-15 pb-15 text-sm border-b border-border
    md:pl-20 md:pr-10
    lg:max-w-none lg:mb-25 lg:px-40
    xl:mr-110 xl:pl-110 xl:pr-0
  `}
`;

const ListNumber = tw.div`
  font-pangramBold w-10 mr-40 text-portfolioText leading-30
  lg:mr-80 lg:text-lg lg:leading-32 lg:-tracking-03
  xl:w-40 xl:mr-180
`;

const ListItemText = tw.div`
  text-second -tracking-024
  lg:text-md lg:leading-30 lg:-tracking-027
  xl:min-w-470
`;

const PersonPublications = ({ person, background, width, scroll }: PersonPublicationsProps) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      Beata1: file(relativePath: { eq: "publication/beata-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Beata2: file(relativePath: { eq: "publication/beata-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Beata3: file(relativePath: { eq: "publication/beata-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Beata4: file(relativePath: { eq: "publication/beata-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Beata5: file(relativePath: { eq: "publication/beata-5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Marta1: file(relativePath: { eq: "publication/marta-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Marta2: file(relativePath: { eq: "publication/marta-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Marta3: file(relativePath: { eq: "publication/marta-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      Marta4: file(relativePath: { eq: "publication/marta-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [activePublication, setPublication] = React.useState(`${person}1`);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const top = useTransform(scroll, [sectionStart + 150, sectionStart + 350], [-60, 0]);
  const opacity = useTransform(scroll, [sectionStart + 150, sectionStart + 350], [0, 1]);

  const oopacity = [
    useTransform(scroll, [sectionStart + 300, sectionStart + 500], [0, 1]),
    useTransform(scroll, [sectionStart + 380, sectionStart + 580], [0, 1]),
    useTransform(scroll, [sectionStart + 460, sectionStart + 660], [0, 1]),
    useTransform(scroll, [sectionStart + 540, sectionStart + 740], [0, 1]),
    useTransform(scroll, [sectionStart + 620, sectionStart + 820], [0, 1]),
  ];

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref} background={background}>
      <Column>
        <Heading style={ width >= 1024 && { top, opacity }}>
          {t('About:Instances')}
          <br/>
          {t('About:Publications')}
        </Heading>
        <PublicationWrapper>
          <PublicationImage fluid={data[activePublication].childImageSharp.fluid} />
        </PublicationWrapper>
      </Column>
      <List>
        {people[person].map((item, index) => (
          <ListItem
            style={ width >= 1024 && { opacity: oopacity[index] }}
            key={`${person}${index}`}
            mdMaxWidth={ index + 1 < 4 }
          >
            <ListNumber>
              { index + 1 }.
            </ListNumber>
            <ListItemText
              className={ item.link && 'cursor-pointer' }
              onMouseEnter={() => setPublication(`${person}${index + 1}`)}
              onClick={() => item.link && window && window.open(item.link,'_blank')}
            >
              {t(`About:Persons:${person}:Publications:${index + 1}`)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default PersonPublications;
