import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowPrimary from "@components/arrows/primary";

const Wrapper = tw.div`relative`;

const Container = tw.div`
  relative w-full max-w-300 mt-42 mx-auto mb-10
  md:top-20 md:-mt-20 md:mb-20
  lg:top-70 lg:left-0 lg:min-w-510 lg:mb-0
  xl:left-90
`;

const Background = tw.div`absolute inset-0 left-12 bottom-22 bg-yellow lg:left-20 lg:bottom-70`;

const Person = tw.div`relative -top-22 w-full overflow-hidden lg:-top-70`;

const PersonMotion = tw(motion.div)`relative`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden
    md:block md:absolute md:bottom-100 md:left-0 md:w-120
    lg:bottom-200 lg:-left-90 lg:w-200
    xl:-left-10 xl:w-220
  `}

  svg {
    ${tw`top-0 left-0 w-full transform rotate-0 -scale-x-100`}
  }
`;

const ImageBeata = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      person: file(relativePath: { eq: "about/beata.png" }) {
        childImageSharp {
          fluid(maxWidth: 580) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const top = useTransform(scroll, [sectionStart + 250, sectionStart + 600], [540, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper>
      <Container>
        <Background />
        <Person ref={Ref}>
          <PersonMotion style={ width >= 1024 && { top } }>
            <Img fluid={data.person.childImageSharp.fluid} />
          </PersonMotion>
        </Person>
      </Container>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
    </Wrapper>
  );
};

export default ImageBeata;
