import React from "react";
import { people } from "@utils/variables";
import tw from "twin.macro";

import Person from "@components/person";
import DetailsBeata from "@components/about/details-beata";
import DetailsMarta from "@components/about/details-marta";
import MoreBeata from "@components/about/more-beata";
import MoreMarta from "@components/about/more-marta";
import ImageBeata from "@components/about/image-beata";
import ImageMarta from "@components/about/image-marta";

const Wrapper = tw.div`mx-20 md:mx-0`;

const People = ({ width, scroll }: { width: number, scroll: any }) => (
  <Wrapper>
    <Person
      isFirstPerson
      name={people[0]}
      moreInfo={<MoreBeata width={ width } scroll={ scroll } />}
      details={<DetailsBeata />}
      image={<ImageBeata width={ width } scroll={ scroll } />}
      width={ width }
      scroll={ scroll }
    />
    <Person
      name={people[1]}
      moreInfo={<MoreMarta width={ width } scroll={ scroll } />}
      details={<DetailsMarta />}
      image={<ImageMarta width={ width } scroll={ scroll } />}
      width={ width }
      scroll={ scroll }
    />
  </Wrapper>
);

export default People;
