import React from "react";
import styled, { keyframes } from "styled-components";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import { PeopleTypes } from "@utils/types";
import { up } from "styled-breakpoints";
import { dot } from "@utils/keyframes";
import tw from "twin.macro";

type DetailsProps = {
  person: PeopleTypes,
  width: number,
  scroll: any
};

const lineAnimation = keyframes`
  0% { transform: scale(0) rotate(12deg); }
  3% { transform: scale(.6) rotate(12deg); }
  6.1% { transform: scale(.2) rotate(12deg); }
  9.1% { transform: scale(1.2) rotate(12deg); }
  12.2% { transform: scale(1) rotate(12deg); }
  69.2% { transform: scale(1) rotate(12deg); }
  73.8% { transform: scale(.8) rotate(12deg); }
  76.8% { transform: scale(1.2) rotate(12deg); }
  81.4% { transform: scale(0) rotate(12deg); }
  100% { transform: scale(0) rotate(12deg); }
`;

const Wrapper = tw.div`relative mb-22 sm:mt-30 md:ml-20 lg:mt-70`;

const PersonName = styled.div`
  ${tw`text-portfolioText mb-10 pt-25 text-icon`}
  
  ${up('lg')} {
    font-size: 50px;
    letter-spacing: -.57px;
  }
  
  ${up('xl')} {
    font-size: 69px;
  }
`;

const PersonPositionWrapper = tw.div`
  ml-10 text-second text-sm -tracking-021
  lg:mt-20 lg:text-md lg:leading-30 lg:-tracking-027
`;

const PersonPosition = tw.div`relative font-pangramBold`;

const PersonTitle = tw.div`relative`;

const Dot = styled.span`
  animation: ${dot} 7s 2.8s infinite;
  ${tw`
    absolute top-80 -left-20 w-10 h-10 bg-blue rounded-full transform scale-0
    lg:top-170 lg:-left-85 lg:w-22 lg:h-22
  `}
`;

const DotTop = styled(Dot)`
  animation-delay: 2s;
  ${tw`top-0 left-60 w-10 h-10 lg:-top-20 lg:left-90 lg:w-20 lg:h-20`}
`;

const Line = styled.span`
  transform: scale(0) rotate(12deg);
  background-color: #761113;
  opacity: .1;
  animation: ${lineAnimation} 7s 2.4s infinite;
  ${tw`absolute hidden sm:block sm:top-55 sm:-left-50 sm:w-40 sm:h-1 lg:top-90 lg:-left-160 lg:w-75`}
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const PersonDetails = ({ person, width, scroll }: DetailsProps) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 230, sectionStart + 380], [0, 280]);
  const leftDelay = useTransform(scroll, [sectionStart + 420, sectionStart + 570], [0, 400]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <DotTop />
      <Dot />
      <Line />
      <PersonName>
        {t(`About:Persons:${person}:Name`)}
      </PersonName>
      <PersonPositionWrapper>
        <PersonPosition>
          {t(`About:Persons:${person}:Position`)}
          <Mask style={ width >= 1024 && { left } } />
        </PersonPosition>
        <PersonTitle>
          {t(`About:Persons:${person}:Title`)}
          <Mask style={ width >= 1024 && { left: leftDelay } } />
        </PersonTitle>
      </PersonPositionWrapper>
    </Wrapper>
  );
};

export default PersonDetails;
