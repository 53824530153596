import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import styled from "styled-components";
import tw from "twin.macro";

import LetterLeft from "@components/svg/letter-left";

const GradientWrapper = tw(fullWidthBox)`
  hidden -top-40 h-0
  md:block
  lg:h-80 lg:mb-110
`;

const Gradient = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%, #F5FDFF 100%);
  ${tw`absolute bottom-0 w-full h-990 -z-1 lg:h-2050`}
`;

const LetterWrapper = tw.div`
  hidden absolute
  md:block md:top-360 md:-right-80 md:w-220
  lg:top-440 lg:-right-160
  xl:top-510 xl:-right-220 xl:w-320
`;

const DetailsBeata = () => (
  <>
    <GradientWrapper>
      <Gradient />
    </GradientWrapper>
    <LetterWrapper>
      <LetterLeft />
    </LetterWrapper>
  </>
);

export default DetailsBeata;
