import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import tw from "twin.macro";

const Wrapper = tw.div`relative md:w-1/2`;

const ImagePerson = tw(Img)`
  max-w-300 w-full mt-40 mx-auto mb-120
  md:-left-50
  lg:-left-100 lg:max-w-none lg:w-400 lg:mt-20
  xl:-left-220
`;

const ImageIllustration = tw(Img)`
  absolute! -bottom-120 right-0 w-220
  md:bottom-20 md:left-100 md:right-auto md:w-250
  lg:bottom-10 lg:left-120 lg:w-370
  xl:left-50
`;

const PersonImageExtra = ({ width, scroll }: { width: number, scroll: any }) => {
  const data = useStaticQuery(graphql`
    query {
      person: file(relativePath: { eq: "about/person.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      illustration: file(relativePath: { eq: "illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 200, sectionStart + 300], [0, 1]);
  const xBeata = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [-330, 0]);
  const x = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [-480, 0]);
  const y = useTransform(scroll, [sectionStart + 200, sectionStart + 450], [720, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <motion.div style={ width >= 1024 && { opacity, x: xBeata } }>
        <ImagePerson fluid={data.person.childImageSharp.fluid} />
      </motion.div>
      <motion.div style={ width >= 1024 && { x, y } }>
        <ImageIllustration fluid={data.illustration.childImageSharp.fluid} />
      </motion.div>
    </Wrapper>
  );
};

export default PersonImageExtra;
