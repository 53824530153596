import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { dot, letterPathLength } from "@utils/keyframes";

const animationDuration = 7;
const animationDelay = 1.2;

const StyledDetails = tw.svg`
  absolute! hidden
  md:block md:w-322
  lg:w-560 lg:top-0 lg:-left-122
`;

const StyledLine = styled.line`
  transform: scale(0);
  transform-origin: 0px 15px;
  animation: ${dot} ${animationDuration}s ${animationDelay}s infinite;
`;

const StyledPath = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${letterPathLength(930)} ${animationDuration}s ${animationDelay}s infinite;
`;

const StyledDot = styled.path`
  transform: scale(0);
  transform-origin: 10px 10px;
  animation: ${dot} ${animationDuration}s ${animationDelay}s infinite;
`;

const DetailsSimple = () => (
  <StyledDetails viewBox="0 0 566 168">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(497.000000, 107.000000)" stroke="#761113" stroke-linejoin="round" stroke-width="0.25">
        <StyledLine x1="0" y1="13" x2="69" y2="0" id="Stroke-13" />
      </g>
      <g transform="translate(482.000000, 148.000000)" stroke="#761113" stroke-linejoin="round" stroke-width="0.25">
        <StyledPath d="M20,10.0046104 C20,15.527893 15.527893,20 9.99538958,20 C4.47210696,20 0,15.527893 0,10.0046104 C0,4.47210696 4.47210696,0 9.99538958,0 C15.527893,0 20,4.47210696 20,10.0046104 Z" />
      </g>
      <g fill="#00A1D6" transform="translate(10, 10)">
        <StyledDot d="M18,8.5 C18,13.1902072 13.9830508,17 9,17 C4.03389831,17 0,13.1902072 0,8.5 C0,3.80979284 4.03389831,0 9,0 C13.9830508,0 18,3.80979284 18,8.5" />
      </g>
    </g>
  </StyledDetails>
);

export default DetailsSimple;
