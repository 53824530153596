import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";
import { motion } from "framer-motion";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import AboutHero from "@components/about/hero";
import People from "@components/about/people";
import CTA from "@components/cta";

const AboutPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <Layout boxHeight={650}>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.About' })}
        description={intl.formatMessage({ id: 'SEO.Description.About' })}
      />
      <AboutHero width={ width } />
      <motion.div
        className="lg:opacity-0"
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{
          duration: .6,
          delay: 1.2,
          ease: [0.13, -0.22, 1, 1.02]
        }}
      >
        <People width={ width } scroll={ scrollY } />
      </motion.div>
      <CTA width={ width } scroll={ scrollY } />
    </Layout>
  );
};

export default AboutPage;
