import React from "react";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

import About from "@components/svg/about";
import Details from "@components/svg/details-simple";
import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`relative md:flex md:mb-40`;

const Column = tw.div`mx-20 md:w-1/2 md:mx-0`;

const Header = styled(motion.div)`
  ${tw`
    ml-20 mb-10 text-xl leading-33 -tracking-016
    sm:ml-0
    md:mt-140 md:mb-60 md:ml-10 md:text-2xl
    lg:relative lg:top-120 lg:mt-130 lg:ml-50 lg:mb-140 lg:text-8xl lg:leading-80 lg:-tracking-037
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const DescriptionWrapper = tw(motion.div)`flex flex-col ml-20 sm:ml-0 lg:opacity-0`;

const Description = tw.p`
  flex text-sm text-second leading-20 -tracking-021
  md:max-w-250 md:-tracking-027
  lg:text-md lg:leading-30 lg:max-w-370
  xl:whitespace-pre-line
`;

const DescriptionCharacter = tw.span`
  font-pangramBold mr-15 leading-32
  md:-tracking-03
  lg:text-lg
`;

const DetailsWrapper = styled.div`
  ${tw`hidden md:block xl:relative`}

  svg {
    ${tw`
      top-10 left-0 w-250 transform -scale-y-100
      lg:top-0 lg:w-350
      xl:top-0 xl:-left-20 xl:w-500
    `}
  }
`;

const DetailsLargeWrapper = styled.div`
  ${tw`
    hidden transform -scale-x-100
    md:block md:absolute md:top-15 md:-right-30 md:w-180
    lg:top-0 lg:-right-40 lg:w-250
    xl:w-340
  `}

  g:nth-child(1), g:nth-child(2), g:nth-child(4) {
    ${tw`hidden`}
  }
`;

const BoxColor = tw.div`absolute hidden md:block`;

const Illustration = tw(motion.div)`
  relative w-300 -mt-40 mx-auto transform rotate-90
  md:w-auto md:-mt-30 md:-ml-50 md:mr-50 md:rotate-0
  lg:opacity-0
  xl:-top-55 xl:-left-172 xl:w-700 xl:m-0
`;

const AboutHero = ({ width }: { width: number }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DetailsWrapper>
        <Details />
      </DetailsWrapper>
      <DetailsLargeWrapper>
        <DetailsLarge />
      </DetailsLargeWrapper>
      <Column>
        <Header
          initial={ width >= 1024 && 'hidden'}
          animate={ width >= 1024 && 'visible' }
          transition={{
            y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
            opacity: { duration: .458, delay: .65, ease: 'linear' },
            rotate: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] }
          }}
          variants={{
            'visible': { y: -120, opacity: 1, rotate: 0 },
            'hidden': { rotate: -8 }
          }}
        >
          {t('About:Header')}
        </Header>
        <DescriptionWrapper
          animate={ width >= 1024 && { opacity: 1 }}
          transition={{ duration: .6, delay: .95, ease: [.88, .14, .12, .86] }}
        >
          <Description>
            <DescriptionCharacter>
              {t('Description Character')}
            </DescriptionCharacter>
            {t('About:Description')}
          </Description>
        </DescriptionWrapper>
      </Column>
      <Column>
        <BoxColor />
        <Illustration
          animate={ width >= 1024 && { opacity: 1 }}
          transition={{ duration: .8, delay: .4, ease: [.88, .14, .12, .86] }}
        >
          <About width={ width } />
        </Illustration>
      </Column>
    </Wrapper>
  );
};

export default AboutHero;
